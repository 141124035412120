import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button, IconButton, MenuItem, Stack, TextField, Autocomplete, Typography, Table,
  TableHead, TableRow, TableCell, TableBody, Chip
} from '@mui/material';
import { Field, FieldArray, useFormikContext } from 'formik';
import createRequisitionModel from './models/createRequisitionModel';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  RequisitionItem, useGetBomsByUnitIdQuery, useGetDevelopmentsByIdQuery, useGetDevelopmentsQuery,
  useGetProcurementProcessesByIdQuery,
  useGetUnitsByProjectByProjectIdQuery
} from '../../redux/vmsApi';

const {
  formField: {
    title,
    development,
    developmentName,
    projectName,
    project,
    items: {
      fields: itemFields
    }
  },
} = createRequisitionModel;

interface RouteParams {
  uuid: string;
}

const CreateRequisitionForm: React.FC = () => {
  const { errors, touched, values, setFieldValue } = useFormikContext<any>();
  const { uuid } = useParams<RouteParams>();
  const [selectedDevelopment, setSelectedDevelopment] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');

  const { data: developments = [] } = useGetDevelopmentsQuery();
  const { data: developmentData } = useGetDevelopmentsByIdQuery(
    { id: selectedDevelopment },
    { skip: !selectedDevelopment }
  );
  const { data: boms = [] } = useGetBomsByUnitIdQuery({ unitId: selectedUnit }, { skip: !selectedUnit });
  const { data: units = [], refetch: refetchUnits } = useGetUnitsByProjectByProjectIdQuery(
    { projectId: selectedProject },
    { skip: !selectedProject }
  );

  const handleDevelopmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const developmentId = event.target.value;
    setSelectedDevelopment(developmentId);
    setFieldValue('development', developmentId);
    setFieldValue('project', '');
    setSelectedProject('');
    setSelectedUnit('');
  };


  

  const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const unitId = event.target.value;
    console.log("Selected unitId:", unitId);
    setSelectedUnit(unitId);
  
    
    const unitObject = units.find(unit => unit.id === unitId);
    const unitName = unitObject ? unitObject.unitName : ''; 
  
    
    const updatedItems = [...values.items];
    updatedItems[index] = { 
      ...updatedItems[index], 
      unit: unitId,
      unitName: unitName 
    };
  
   
    setFieldValue('items', updatedItems);
    
    
    console.log("After setFieldValue:", updatedItems);
  };
  

  const projects = selectedDevelopment
    ? developments.find((dev) => dev.id === selectedDevelopment)?.projects || []
    : [];

  const handleProjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const projectId = event.target.value;
    const selectedProjectObject = projects.find(project => project.projectId === projectId);
    setSelectedProject(projectId);
    setFieldValue('project', projectId);
    if (selectedProjectObject) {
      setFieldValue('projectName', selectedProjectObject.name);
    }
  };

  const unitOptions = [
    { value: 'm' },
    { value: 'm²' },
    { value: 'm³' },
    { value: 'kg' },
    { value: 'lt' },
    { value: 'item' },
    { value: 'no unit' },
  ];

  useEffect(() => {
    if (selectedProject) {
      refetchUnits();
    }
  }, [selectedProject, refetchUnits]);

  useEffect(() => {
    if (developmentData) {

      console.log('Selected development data:', developmentData);
    }
  }, [developmentData]);

  useEffect(() => {
    console.log("Updated unit values:", values.items);
  }, [values.items]);

  const bomItems = boms.flatMap((bom: { sections: any[]; }) =>
    bom.sections.flatMap(section =>
      section.items.map((item: { itemId: any; description: any; quantity: any; quantityUnit: any; }) => ({
        itemId: item.itemId,
        description: item.description,
        quantityValue: item.quantity || 0,
        quantityUnit: item.quantityUnit || '',
      }))
    )
  );

  const handleAutocompleteChange = (newValue: { itemId: string; description: string; quantityValue: string | number; quantityUnit: string; } | null, index: number) => {
    if (newValue) {
      setFieldValue(`items[${index}].description`, newValue.description);
      setFieldValue(`items[${index}].quantity.quantityValue`, newValue.quantityValue);
      setFieldValue(`items[${index}].quantity.quantityUnit`, newValue.quantityUnit);
    } else {
      setFieldValue(`items[${index}].description`, '');
      setFieldValue(`items[${index}].quantity.quantityValue`, '');
      setFieldValue(`items[${index}].quantity.quantityUnit`, '');
    }
  };

  const { data: procurementProcess, isLoading, isError } = useGetProcurementProcessesByIdQuery({ id: uuid }, { skip: !uuid });

  useEffect(() => {
    if (procurementProcess) {
      const { requisition } = procurementProcess;
      if (requisition) {
        setFieldValue('title', requisition.title);
        setFieldValue('development', requisition.development);
        setFieldValue('project', requisition.project);
        requisition.items.forEach((item, index) => {
          setFieldValue(`items[${index}].description`, item.description);
          setFieldValue(`items[${index}].quantity.quantityValue`, item.quantity.quantityValue);
          setFieldValue(`items[${index}].quantity.quantityUnit`, item.quantity.quantityUnit);
          setFieldValue(`items[${index}].unit`, item.unit);
        });
      }
    }
  }, [procurementProcess, setFieldValue]);

  useEffect(() => {
    if (developmentData) {
      setFieldValue('developmentName', developmentData.name);
    }
  }, [developmentData, setFieldValue]);

  return (
    <Stack width="100%" spacing={5}>
      {procurementProcess?.currentTask === "CorrectPurchaseRequisition" && (
        <>
          <Chip label="Requisition rejected" color="error" />
          <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
            {procurementProcess?.requisition?.title}
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Stand</TableCell>
                <TableCell>Procured</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(procurementProcess?.requisition?.items || []).map((item: RequisitionItem, index: number) => (
                <TableRow key={index}>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.quantity?.quantityValue} {item.quantity?.quantityUnit}</TableCell>
                  <TableCell>{item.unit.name}</TableCell>
                  <TableCell>{item.procured ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
            Requisition Reviewer's Comments
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Reviewer</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell>Decision</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {procurementProcess?.siteManagerReview?.comment && (
                <TableRow>
                  <TableCell>{procurementProcess?.siteManagerReview?.user?.name}({ procurementProcess?.siteManagerReview?.user?.roles?.[0] ?? 'No Role'})</TableCell>
                  <TableCell>{procurementProcess?.siteManagerReview?.comment}</TableCell>
                  <TableCell>
                    <Chip
                      label={procurementProcess?.siteManagerReview?.approved ? 'Approved' : 'Rejected'}
                      color={procurementProcess?.siteManagerReview?.approved ? 'success' : 'error'}
                    />
                  </TableCell>
                </TableRow>
              )}
              {procurementProcess?.cpoReview?.comment && (
                <TableRow>
                  <TableCell>
  {procurementProcess?.cpoReview?.user?.name ?? 'N/A'}
  (
  {procurementProcess?.cpoReview?.user?.roles?.join(', ') || 'No Roles'}
  )
</TableCell>

                  
                  <TableCell>{procurementProcess?.cpoReview?.comment}</TableCell>
                  <TableCell>
                    <Chip
                      label={procurementProcess?.cpoReview?.approved ? 'Approved' : 'Rejected'}
                      color={procurementProcess?.cpoReview?.approved ? 'success' : 'error'}
                    />
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
              <TableCell colSpan={4}>No  comments from other reviewers yet.</TableCell>
            </TableRow>



            </TableBody>
          </Table>
        </>
      )}


      <Stack width="100%" spacing={5}>
        <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
          {procurementProcess?.requisition ? 'Edit Requisition' : 'Add Requisition'}
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Field
            as={TextField}
            fullWidth
            {...title}
            error={touched[title.name] && Boolean(errors[title.name])}
            helperText={touched[title.name] && errors[title.name] ? errors[title.name] : ''}
          />

          <Field
            as={TextField}
            fullWidth
            select
            {...development}
            onChange={handleDevelopmentChange}
            error={touched[development.name] && Boolean(errors[development.name])}
            helperText={touched[development.name] && errors[development.name] ? errors[development.name] : ''}
          >
            {developments.map((development) => (
              <MenuItem key={development.id} value={development.id}>
                {development.name}
              </MenuItem>
            ))}
          </Field>

          <Field
            {...developmentName}
            type="hidden"
          />

          <Field
            {...projectName}
            type="hidden"
          />

          <Field
            as={TextField}
            fullWidth
            select
            {...project}
            onChange={handleProjectChange}
            error={touched[project.name] && Boolean(errors[project.name])}
            helperText={touched[project.name] && errors[project.name] ? errors[project.name] : ''}
          >
            {projects.map((project) => (
              <MenuItem key={project.projectId} value={project.projectId}>
                {project.name}
              </MenuItem>
            ))}
          </Field>
        </Stack>

        <FieldArray name="items">
          {({ push, remove }) => (
            <>
              {values.items.map((item: RequisitionItem, index: number) => (
                <Stack direction="row" spacing={2} alignItems="center" key={index}>
                  <Field
                    as={TextField}
                    fullWidth
                    select
                    {...itemFields.unit}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUnitChange(event, index)}  
                    error={touched[`items[${index}].unit.name`] && Boolean(errors[`items[${index}].unit`])}
                    helperText={touched[`items[${index}].unit.name`] && errors[`items[${index}].unit.name`] ? errors[`items[${index}].items.name`] : ''}
                  >
                    {units.map((unit) => (
                      <MenuItem key={unit.id} value={unit.id}>
                        {unit.unitName}
                      </MenuItem>
                    ))}
                  </Field>

                  <Field
                      {...itemFields.unitName}
                      type="hidden"
                   />


                  <Autocomplete
                    options={bomItems}
                    getOptionLabel={(option: { itemId: string; description: string; quantityValue: string | number; quantityUnit: string }) => option.description}
                    onChange={(event, newValue) => handleAutocompleteChange(newValue, index)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select BOM Item"
                        variant="outlined"
                        fullWidth
                        error={touched.selectedBomItem && Boolean(errors.selectedBomItem)}
                        helperText={touched.selectedBomItem && errors.selectedBomItem ? errors.selectedBomItem : ''}
                      />
                    )}
                  />


               <TextField
                    {...itemFields.description}
                    variant="outlined"
                    fullWidth
                    type="hidden"
                    style={{ display: 'none' }}
                    value={values.items[index]?.description || ''}
                    onChange={(e) => setFieldValue(`items[${index}].description`, e.target.value)}
                  /> 

                 


                  <TextField
                    {...itemFields.quantity.quantityValue}
                    variant="outlined"
                    
                    value={values.items[index]?.quantity?.quantityValue}
                    onChange={(e) => setFieldValue(`items[${index}].quantity.quantityValue`, e.target.value)}
                  />
                  <Field
                    as={TextField}
                    select
                    value={values.items[index]?.quantity?.quantityUnit || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue(`items[${index}].quantity.quantityUnit`, e.target.value)
                    }
                    {...itemFields.quantity.quantityUnit}
                    error={touched[`items[${index}].quantity.quantityUnit.name`] && Boolean(errors[`items[${index}].quantity.quantityUnit.name`])}
                    helperText={touched[`items[${index}].quantity.quantityUnit.name`] && errors[`items[${index}].quantity.quantityUnit.name`] ? errors[`items[${index}].quantity.quantityUnit.name`] : ''}
                    sx={{ minWidth: '100px' }}
                  >
                    {unitOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Field>
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              ))}

              <Button
                onClick={() => push({ description: '', quantity: { quantityValue: '', quantityUnit: '' }, unit: '' })}
                variant="outlined"
                color="primary"
                size="small"
              >
                Add Requisition Item
              </Button>
            </>
          )}
        </FieldArray>

      </Stack>

    </Stack>
  );
};

export default CreateRequisitionForm;
