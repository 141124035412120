import React, {useEffect, useState} from 'react';
import {DialogTitle, DialogContent, Stack, Button, Typography, Link, IconButton} from "@mui/material";
import CreateAccountForm from '../forms/CreateAccountForm';
import {Formik, Form} from 'formik';
import createAccountModel, {createAccountModelType} from '../forms/models/createAccountModel';
import createAccountSchema from '../forms/schemas/createAccountSchema';
import * as Yup from 'yup';
import {usePostUsersMutation, UserWithPassword} from '../../redux/vmsApi';
import LoadingCover from '../common/LoadingCover';
import authSlice from "../../redux/reducers/authSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {Close} from '@mui/icons-material';
import useIsMobile from '../../hooks/useIsMobile';

const { formField: {createAccountEmail} } = createAccountModel;

type MyFormValues = createAccountModelType;

const createAccountModelInitial = createAccountModel.initialValues;

const CreateAccountFormSchema = Yup.object().shape({
    ...createAccountSchema,
    createAccountGender: Yup.string().required(createAccountModel.formField.createAccountGender.requiredErrorMsg),
});

interface OwnProps {
    close: () => void;
    showLoginMsg?: boolean;
}

const CreateAccount = (props: OwnProps) => {
    const { close, showLoginMsg = true } = props;
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();
    const [updateUserCreate, { isSuccess, isLoading, isError, error }] = usePostUsersMutation();
    const [createError, setCreateError] = useState('');
    const initialEmail = useAppSelector(state => state.auth.initialEmail);
    const authMessage = useAppSelector(state => state.auth.authMessage);

    const initialValues: MyFormValues = {
        ...createAccountModelInitial,
        [createAccountEmail.name]: initialEmail,
    };

    const handleSubmit = async (values: MyFormValues) => {
        const data = {
            name: values.createAccountName,
            department: values.createdAccountDepartment,
            email: values.createAccountEmail,
            password: values.createAccountPass,
            gender: values.createAccountGender, // Include gender in submission
        } as UserWithPassword;
        await updateUserCreate({ userWithPassword: data });
    };

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                close();
            }, 300);
        }
    }, [isSuccess, close]);

    useEffect(() => {
        if (isError && error) {
            const err: any = error;
            if (err?.data?.error?.message) {
                setCreateError(err?.data?.error?.message);
            }
        } else {
            setCreateError('');
        }
    }, [isError, error]);

    function showLogin() {
        dispatch(authSlice.actions.setAuthState('login'));
    }

    return (
        <React.Fragment>
            <DialogTitle
                data-cy="dialogue-title"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: isMobile ? 'space-between' : 'center',
                }}
            >
                <Typography variant="h2">Create Account</Typography>
                {isMobile ? (
                    <IconButton onClick={close}>
                        <Close sx={{ fontWeight: 'bold', fontSize: 26 }} />
                    </IconButton>
                ) : null}
            </DialogTitle>

            <DialogContent>
                <Typography align="center" variant="body1">
                    {authMessage}
                </Typography>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={CreateAccountFormSchema}
                >
                    <Form>
                        <Stack spacing={8} width="100%" alignItems="center">
                            <CreateAccountForm apiError={createError} />
                            <Button
                                variant="contained"
                                type="submit"
                                data-cy={"createAccountSubmit"}
                            >
                                Create
                            </Button>
                            {showLoginMsg && (
                                <Typography align="center" variant="body1" noWrap>
                                    Already have an account? <Link onClick={showLogin}>Login</Link>
                                </Typography>
                            )}
                        </Stack>
                    </Form>
                </Formik>
            </DialogContent>

            {(isLoading || isSuccess) && <LoadingCover isSuccess={isSuccess} />}
        </React.Fragment>
    );
};

export default CreateAccount;
