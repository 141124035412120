const unitFormModel = {
  formId: 'unitForm',
  formField: {
    unitName: {
      name: 'unitName',
      label: 'Unit Name',
      required: true,
      requiredErrorMsg: 'Unit Name is required',
    },
    project: {
      name: 'project',
      label: 'Project',
      required: true,
      requiredErrorMsg: 'Project is required',
    },
  },
  initialValues: {
    unitName: '',
    project: ''
  },
};

export type UnitType = {
  unitName: string;
  project: string;
};

export default unitFormModel;
