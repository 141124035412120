import React, { ChangeEvent, useState } from 'react';
import {
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent,
} from '@mui/material';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import PageWithHeader from "../common/PageWithHeader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { push } from "connected-react-router/immutable";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
  useGetUsersQuery,
  User,
  usePostFeedbackSurveyMutation, FeedbackSurveyData
} from "../../redux/vmsApi";
import {generatePastQuarters} from "../../utils/periodUtil";

interface SurveyValues {
  employee: string;
  reviewPeriod: string;
  responses: {
    [key: string]: boolean | string;
  };
}

const initialValues: SurveyValues = {
  employee: '',
  reviewPeriod: '',
  responses: {},
};

const validationSchema = Yup.object({
  employee: Yup.string().required('Employee is required'),
  responses: Yup.object().shape({
    respectValues: Yup.boolean(),
    decisionValues: Yup.boolean(),
    encourageValues: Yup.boolean(),
    improveValues: Yup.string().optional(),
    goodDecisions: Yup.boolean(),
    highStandards: Yup.boolean(),
    motivates: Yup.boolean(),
    improveLeadership: Yup.string().optional(),
    controlEmotions: Yup.boolean(),
    awareness: Yup.boolean(),
    strongSkills: Yup.boolean(),
    empathy: Yup.boolean(),
    selfMotivated: Yup.boolean(),
    improveEmotional: Yup.string().optional(),
    urgentWork: Yup.boolean(),
    meetsDeadlines: Yup.boolean(),
    exceedsExpectations: Yup.boolean(),
    improveProductivity: Yup.string().optional(),
    fullUnderstanding: Yup.boolean(),
    expertSkills: Yup.boolean(),
    improveJobKnowledge: Yup.string().optional(),
    gracefulDisagree: Yup.boolean(),
    adaptsEasily: Yup.boolean(),
    equalTreatment: Yup.boolean(),
    improveInterpersonal: Yup.string().optional(),
    clearGoalsSelf: Yup.boolean(),
    clearGoalsTeam: Yup.boolean(),
    performanceReviews: Yup.boolean(),
    manageUnderperformance: Yup.boolean(),
    improvePerformance: Yup.string().optional(),
    strength: Yup.string().optional(),
    opportunity: Yup.string().optional(),
  }),
});

const SurveyForm: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext<SurveyValues>();
  const { data: users } = useGetUsersQuery();

  const selectedUser = users?.find(user => user.id === values.employee);
  const pronounOne = selectedUser?.gender === 'male' ? 'he' : 'she';
  const pronounTwo = selectedUser?.gender === 'male' ? 'his' : 'her';
  const pronounThree = selectedUser?.gender === 'male' ? 'himself' : 'herself';
  const name = selectedUser?.name || '[Name]';

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFieldValue(name, checked);
  };

  const questions = [
    {
      title: 'VALUES',
      items: [
        { id: 'respectValues', label: `Respect the WestProp Values evident in ${name}'s conduct` },
        { id: 'decisionValues', label: `Makes decisions in line with WestProp values` },
        { id: 'encourageValues', label: `Encourages team members to live the WestProp values` },
      ],
    },
    { id: 'improveValues', label: `What suggestions do you have to help ${name} improve ${pronounTwo} living of the WestProp values`, type: 'text' },
    {
      title: 'LEADERSHIP SKILLS',
      items: [
        { id: 'goodDecisions', label: `Makes good decisions and trusts the team when delegating tasks` },
        { id: 'highStandards', label: `The team is working to a high standard and cohesively towards the high-level goals and objectives of the department` },
        { id: 'motivates', label: `Motivates others to reach their goals` },
      ],
    },
    { id: 'improveLeadership', label: `What suggestions do you have to help ${name} improve ${pronounTwo} leadership skills`, type: 'text' },
    {
      title: 'EMOTIONAL INTELLIGENCE',
      items: [
        { id: 'controlEmotions', label: `Controls ${pronounTwo} emotions even in high pressure situations` },
        { id: 'awareness', label: `Demonstrates an awareness of how ${pronounTwo} actions and decisions affect others` },
        { id: 'strongSkills', label: `Has strong skills and is able to interact well with others` },
        { id: 'empathy', label: `Demonstrates empathy and is able to recognize and respond appropriately to the emotional state of others` },
        { id: 'selfMotivated', label: `Is self motivated, striving to achieve ${pronounTwo} goal and always looking for ways to do better` },
      ],
    },
    { id: 'improveEmotional', label: `What suggestions do you have to help ${name} improve ${pronounTwo} emotional intelligence`, type: 'text' },
    {
      title: 'PRODUCTIVITY',
      items: [
        { id: 'urgentWork', label: `Delivers urgent work without compromising on the details` },
        { id: 'meetsDeadlines', label: `Meets deadlines and makes the best time use` },
        { id: 'exceedsExpectations', label: `Exceeds expectations by delivering more than assigned work despite the tight schedule` },
      ],
    },
    { id: 'improveProductivity', label: `What suggestions do you have to help ${name} improve ${pronounTwo} productivity`, type: 'text' },
    {
      title: 'JOB KNOWLEDGE',
      items: [
        { id: 'fullUnderstanding', label: `Has full understanding of ${pronounTwo} role and responsibility in ${pronounTwo} department` },
        { id: 'expertSkills', label: `Is an expert and performs job responsibilities skillfully` },
      ],
    },
    { id: 'improveJobKnowledge', label: `What suggestions do you have to help ${name} improve ${pronounTwo} job knowledge`, type: 'text' },
    {
      title: 'INTERPERSONAL SKILLS',
      items: [
        { id: 'gracefulDisagree', label: `Even when disagreeing with others, ${name} does it gracefully and respectfully` },
        { id: 'adaptsEasily', label: `Adapts easily to various situations and different people` },
        { id: 'equalTreatment', label: `Equal in ${pronounTwo} treatment of everyone and communicates with ${pronounTwo} peers respectfully` },
      ],
    },
    { id: 'improveInterpersonal', label: `What suggestions do you have to help ${name} improve ${pronounTwo} interpersonal skills`, type: 'text' },
    {
      title: 'PERFORMANCE MANAGEMENT',
      items: [
        { id: 'clearGoalsSelf', label: `Sets clear goals, objectives and targets for ${pronounThree}` },
        { id: 'clearGoalsTeam', label: `Sets and communicates clear goals, objectives and targets for ${pronounTwo} team` },
        { id: 'performanceReviews', label: `Conducts performance review meetings/appraisals with ${pronounTwo} team timorously and objectively` },
        { id: 'manageUnderperformance', label: `Is proactive in managing underperformance of ${pronounTwo} team by initiating and monitoring appropriate interventions e.g. training` },
      ],
    },
    { id: 'improvePerformance', label: `What suggestions do you have to help ${name} improve ${pronounTwo} performance management and execution`, type: 'text' },
    { id: 'strength', label: `What is ${name}'s greatest strength and what should ${pronounOne} continue to do to grow and develop?`, type: 'text' },
    { id: 'opportunity', label: `What is ${name}'s greatest opportunity and what can ${pronounOne} do to improve ${pronounTwo} performance?`, type: 'text' },
  ];

  return (
    <Stack spacing={4} width="100%">
      {questions.map((question, qIndex) =>
        question.items ? (
          <React.Fragment key={qIndex}>
            <Typography variant="h6">{question.title}</Typography>
            {question.items.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Field
                    type="checkbox"
                    name={`responses.${item.id}`}
                    as={Checkbox}
                    disabled={disabled}
                    onChange={handleCheckboxChange}
                  />
                }
                label={item.label}
              />
            ))}
          </React.Fragment>
        ) : (
          <Field
            key={qIndex}
            name={`responses.${question.id}`}
            as={TextField}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            label={question.label}
            required
            disabled={disabled}
          />
        )
      )}
    </Stack>
  );
};

const SurveyFormPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data: users } = useGetUsersQuery();
  const [postFeedbackSurvey] = usePostFeedbackSurveyMutation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const currentUser = useAppSelector(state => state.auth.user);
  const pastQuarters = generatePastQuarters();

  const handleSubmit = async (values: SurveyValues, { resetForm }: { resetForm: () => void }) => {
    const dataToSubmit: FeedbackSurveyData = {
      userId: values.employee,
      reviewerId: currentUser?.id ?? '',
      reviewPeriod: values.reviewPeriod,
      responses: Object.keys(values.responses).map(key => {
        const response = values.responses[key];
        return {
          questionId: key,
          responseBoolean: typeof response === 'boolean' ? response : undefined,
          responseText: typeof response === 'string' ? response : undefined,
        };
      }),
    }
    try {
      const response = await postFeedbackSurvey({ feedbackSurveyData: dataToSubmit }).unwrap();
      setIsSubmitted(true);
      resetForm();
    } catch (error) {
      console.error('There was an error submitting the form', error);
    }
  };

  return (
    <PageWithHeader>
      {!isSubmitted ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            const formDisabled = !values.employee;
            return (
              <Form>
                <Stack spacing={4} width="100%" p={10} pb={5}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Button color="primary" onClick={() => dispatch(push('/reviews'))}>
                        <ArrowBackIcon />
                        <Typography color="primary" align="left">Dashboard</Typography>
                      </Button>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                      <Button color="primary" onClick={() => dispatch(push('/reviews/360-degree-survey/feedback'))}>
                        <Typography color="primary" align="right">View Feedback</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                    360 Degree Survey
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Please fill out this 360-degree survey to provide feedback on the individual's performance. Your responses will help in understanding strengths and areas of improvement.
                  </Typography>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel>Employee</InputLabel>
                    <Field
                      name="employee"
                      as={Select}
                      label="Employee"
                      onChange={(event: SelectChangeEvent) => {
                        setFieldValue('employee', event.target.value);
                      }}
                    >
                      {users && users.map((user: User) => (
                        <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel>Review Period</InputLabel>
                    <Field
                      name="reviewPeriod"
                      as={Select}
                      label="Review Period"
                      onChange={(event: SelectChangeEvent) => {
                        setFieldValue('reviewPeriod', event.target.value);
                      }}
                    >
                      {pastQuarters.map((quarter, index) => (
                        <MenuItem key={index} value={quarter}>{quarter}</MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                  <SurveyForm disabled={formDisabled} />
                  <Button variant="contained" type="submit" sx={{ mt: 4 }} disabled={formDisabled}>
                    Submit
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Stack spacing={4} width="100%" p={10} pb={5}>
          <Typography variant='h5' gutterBottom style={{ color: '#123456' }}>
            Submission Successful!
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                disabled
              />
            }
            label="Thank you for your feedback."
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(push('/reviews'))}
          >
            Back to Dashboard
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsSubmitted(false)}
          >
            Submit Another
          </Button>
        </Stack>
      )}
    </PageWithHeader>
  );
};

export default SurveyFormPage;
