import React, {useEffect, useState} from 'react';
import {Button, Stack, Typography} from "@mui/material";
import {Form, Formik} from 'formik';
import createAgreementModel, {createInvestmentAgreementModelType} from '../forms/models/createAgreementModel';
import {Agreement, AgreementData, usePostCreateAgreementMutation,} from '../../redux/vmsApi'
import LoadingCover from '../common/LoadingCover'
import CreateAgreementForm from "../forms/CreateAgreementForm";
import createAgreementSchema from "../forms/schemas/createAgreementSchema";
import PageWithHeader from '../common/PageWithHeader';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {enGB} from 'date-fns/locale'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import config from "../../config";
import {format, isValid} from "date-fns";

type MyFormValues = createInvestmentAgreementModelType

const createInvestmentAgreementModelInitial = createAgreementModel.initialValues

type Props = {
  onSuccess?: (agreement: Agreement) => void;
};

const CreateAgreementPage: React.FC<Props> = ({onSuccess}) => {
  const [createAgreement, {isSuccess, isLoading}] = usePostCreateAgreementMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false)
        setShowSuccessMsg(true)
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: MyFormValues = {
    ...createInvestmentAgreementModelInitial,
  };

  const downloadFile = (url: string, fileName = 'download') => {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleSubmit = async (values: MyFormValues) => {
    const data: Partial<AgreementData> = {
      contractReference: values.contractReference,
      clientName: values.clientName,
      clientIdNumber: values.clientIdNumber,
      clientDob: values.clientDob,
      clientAddress: values.clientAddress,
      company: values.company, // Assuming this is a string ID or similar
      representedBy: values.representedBy, // Assuming this is a string ID or similar
      purchasePrice: values.purchasePrice, // Ensure this is a number
      unitNumber: values.unitNumber,
      landShare: values.landShare,
      paymentsToDate: values.paymentsToDate.map(payment => ({
        ...payment,
        date: format(new Date(payment.date), 'yyyy-MM-dd') // Reformat each payment date
      })),
      numberOfInterestFreeMonths: values.numberOfInterestFreeMonths, // Ensure this is a number
      propertyDescription: values.propertyDescription,
      unitArea: values.unitArea, // Ensure this is a number
      chargeInterest: values.chargeInterest
    }

    if (values.company === 'pomona') {
      data.block = values.phase;
    }

    if (values.company === 'seatrite') {
      data.block = values.block;
    }

    if (values.clientCompanyName) {
      data.clientCompanyName = values.clientCompanyName;
    }

    if (values.firstInstallmentPaymentDate !== null) {
      if (isValid(values.firstInstallmentPaymentDate)) {
        data.firstInstallmentPaymentDate = format(new Date(values.firstInstallmentPaymentDate), 'yyyy-MM-dd');
      } else {
        data.firstInstallmentPaymentDate = values.firstInstallmentPaymentDate;
      }
    }
    if (values.lastInstallmentPaymentDate !== null) {
      if (isValid(values.lastInstallmentPaymentDate)) {
        data.lastInstallmentPaymentDate = format(new Date(values.lastInstallmentPaymentDate), 'yyyy-MM-dd');
      } else {
        data.lastInstallmentPaymentDate = values.lastInstallmentPaymentDate;
      }
    }

    const agreementData: AgreementData = data as AgreementData;
    setShowOverlay(true)
    try {
      const result = await createAgreement({agreementData: agreementData}).unwrap();
      setShowOverlay(false);
      setShowOverlay(false);
      if (result) {
        const downloadLink = `${config.CDN_URL}/agreements/${result.id}.pdf`
        downloadFile(downloadLink, `${values.unitNumber}-contract.pdf`);
      }
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      // Handle error state
      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  }

  if (showSuccessMsg) {
    return (
      <Typography>Thank you, your response has been received</Typography>
    )
  }

  return (
    <PageWithHeader>
      {showOverlay && <LoadingCover isSuccess={isSuccess}/>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={createAgreementSchema}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              <CreateAgreementForm/>
              <Button
                variant="contained"
                type="submit"
              >
                Generate
              </Button>
            </Stack>
          </Form>
        </LocalizationProvider>
      </Formik>
    </PageWithHeader>
  )
}

export default CreateAgreementPage;
