import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardHeader
} from "@mui/material";

interface OwnProps {
  children: React.ReactNode
}

const InviteDetailsContainer: React.FC<OwnProps> = (props) => {
  const {children} = props;
  return (
    <Card raised={true} sx={{ borderRadius: 2, margin: 2 }}>
      <CardHeader>
        <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Invite Details</Typography>
      </CardHeader>
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
};

export default InviteDetailsContainer;
