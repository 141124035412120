import {vmsApi as api, User} from '../vmsApi';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import type {RootState} from '../store'

interface initialStateInterface {
    loginModalOpen: boolean;
    authState: 'login' | 'reset' | 'create';
    authMessage: string;
    initialEmail: string;
    user: User | null;
    showCompleteProfileBanner: boolean;
    showVerifyEmailBanner: boolean;
}

const initialState:initialStateInterface = {
    loginModalOpen: false,
    authState: 'login',
    authMessage: '',
    initialEmail: '',
    user: null,
    showCompleteProfileBanner: true,
    showVerifyEmailBanner: true
}

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        openLoginModal(state) {
            state.loginModalOpen = true;
        },
        closeLoginModal(state) {
            state.loginModalOpen = false;
            state.authMessage = '';
        },
        setAuthState(state, action) {
            state.authState = action.payload;
        },
        setAuthMessage(state, action) {
            state.authMessage = action.payload;
        },
        setInitialEmail(state, action) {
            state.initialEmail = action.payload;
        },
        setUser(state, action) {
            state.user = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
        api.endpoints.postUsersLogin.matchFulfilled,
        (state, { payload }) => {
            state.user = payload;
        }
        );
        builder.addMatcher(
        api.endpoints.getUsersMe.matchFulfilled,
        (state, {payload}) => {
            state.user = payload;
        });
        builder.addMatcher(
          api.endpoints.postUsersLogout.matchFulfilled,
          (state) => {
              document.cookie = "jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              state.user = null;
          });
    },
})


export const logoutUser = createAsyncThunk('users/logout', async (payload: void, {dispatch}) => {
    try {
        const result = dispatch(api.endpoints.postUsersLogout.initiate());
        await result;
    } catch (error) {
        console.error('Error during logout:', error);
    }
});

export const selectCurrentUser = (state: RootState) => state.auth.user

export default authSlice;
