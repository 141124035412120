import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import {EmployeeReview, UserWithRelations} from "../../redux/vmsApi";

interface OwnProps {
  reviewData: EmployeeReview;
  userData: UserWithRelations | null;
}

const SPEARTable: React.FC<OwnProps> = (props) => {
  const {reviewData, userData} = props;
  return (
      <TableContainer component={Paper}>
        <Table aria-label="employee review details">
          <TableBody>
            <TableRow>
              <TableCell>Employee Name</TableCell>
              <TableCell>{userData?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Department</TableCell>
              <TableCell>{userData?.department ?? 'Not specified'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Review Period</TableCell>
              <TableCell>{reviewData?.reviewPeriod}</TableCell>
            </TableRow>
            {reviewData?.accomplishments && reviewData.accomplishments.map((item, index) => (
              <TableRow key={`accomplishment-${index}`}>
                <TableCell>Accomplishment: {item.title}</TableCell>
                <TableCell>Rating: {item.rating}</TableCell>
              </TableRow>
            ))}
            {reviewData?.quarterlyObjective && (
              <TableRow>
                <TableCell>Quarterly Objective</TableCell>
                <TableCell>{reviewData.quarterlyObjective}</TableCell>
              </TableRow>
            )}
            {reviewData?.quarterlyGoals && reviewData.quarterlyGoals.map((item, index) => (
              <TableRow key={`quarterlyGoal-${index}`}>
                <TableCell>Quarterly Goal: {item.title}</TableCell>
                <TableCell>Rating: {item.rating}</TableCell>
              </TableRow>
            ))}
            {reviewData?.monthlyGoals && reviewData.monthlyGoals.map((item, index) => (
              <TableRow key={`monthlyGoal-${index}`}>
                <TableCell>Monthly Goal: {item.title}</TableCell>
                <TableCell>Rating: {item.rating}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Areas to Improve</TableCell>
              <TableCell>{reviewData?.areaToImprove}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Examples of Excellence</TableCell>
              <TableCell>{reviewData?.examplesOfExcellence}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Main Goal to Achieve Next Month</TableCell>
              <TableCell>{reviewData?.mainGoalToAchieve}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Turns up on time</TableCell>
              <TableCell>{reviewData?.turnUpOnTime ? 'Yes' : 'No'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Daily check in completed</TableCell>
              <TableCell>{reviewData?.dailyCheckIn ? 'Yes' : 'No'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Reports on time</TableCell>
              <TableCell>{reviewData?.reportsOnTime ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default SPEARTable;
