import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AddPaymentForm from './AddPaymentForm';
import Clients from "./Clients";
import PropertyRegisterDashboard from "./PropertyRegisterDashboard";
import Properties from "./Properties";

const PropertyRegisterRoutes = () => {
  return (
    <Switch>
      <Route exact path="/property-register" component={PropertyRegisterDashboard} />
      <Route exact path="/property-register/add-payment" component={AddPaymentForm} />
      <Route exact path="/property-register/clients" component={Clients} />
      <Route exact path="/property-register/properties" component={Properties} />
      <Route exact path="/property-register/view" component={Properties} />

    </Switch>
  );
}

export default PropertyRegisterRoutes;
