import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Stack, Typography } from "@mui/material";
import { Form, Formik } from 'formik';
import {
  Requisition,
  RequisitionData,
  RequisitionItem,
  useGetProcurementProcessesByIdQuery,
  useGetUsersMeQuery,
  usePostCreateProcurementProcessMutation,
  usePutProcurementProcessesByIdMutation,
} from '../../redux/vmsApi';
import LoadingCover from '../common/LoadingCover';
import PageWithHeader from '../common/PageWithHeader';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from 'date-fns/locale';

import createRequisitionModel, { createRequisitionModelType } from "../forms/models/createRequisitionModel";
import CreateRequisitionForm from "../forms/CreateRequisitionForm";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ProcurementProcessDashboard from './ProcurementProcessDashboard';

type MyFormValues = createRequisitionModelType;

const createRequisitionModelInitial = createRequisitionModel.initialValues;

interface RouteParams {
  uuid: string;
}

const CreateRequisitionPage: React.FC<{ onSuccess?: (requisition: Requisition) => void; }> = ({ onSuccess }) => {
  const { uuid } = useParams<RouteParams>();
  const [updateProcurementProcess, { isLoading: isSubmitting }] = usePutProcurementProcessesByIdMutation();
  const { data: user } = useGetUsersMeQuery();
  const [createProcurementProcessForm, { isLoading: isSecondFormLoading }] = usePostCreateProcurementProcessMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [requisitionData, setRequisitionData] = useState<RequisitionData | null>(null);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);

  const { data: procurementProcess, isLoading, isError } = useGetProcurementProcessesByIdQuery({ id: uuid }, { skip: !uuid });

  useEffect(() => {
    setShowOverlay(isSubmitting || isSecondFormLoading);
    if (showSuccessMsg) {
      setTimeout(() => {
        setShowOverlay(false);
        
        setRedirectToDashboard(true);
      }, 3000);
    }
  }, [isSubmitting, isSecondFormLoading, showSuccessMsg]);

  const initialValues: MyFormValues = {
    ...createRequisitionModelInitial,
  };

  const handleSubmit = async (values: MyFormValues) => {
    const requisitionItems: RequisitionItem[] = values.items.map(item => ({
      description: item.description,
      quantity: {
        quantityValue: item.quantity.quantityValue,
        quantityUnit: item.quantity.quantityUnit,
      },
      unit:{
             id:item.unit,
             name:item.unitName
      },
      procured: false,
    }));

    console.log("requisitionItems",requisitionItems);

    const reqData: RequisitionData = {
      title: values.title,
      development:{ 
        name:values.developmentName,
        id:values.development
      },
      project: { 
        name:values.projectName,
        id:values.project
      },
      items: requisitionItems,
      approved: false,
    };
    setRequisitionData(reqData);

    setShowOverlay(true);
    try {
      let result;
      let procurementProcessPartial: any;
      let newProcurementProcess: any;
      let formData = new FormData();
    
      if (procurementProcess?.requisition) {
        procurementProcessPartial = {
          currentTask: "SiteManagerReviewPurchaseRequisition",
          previousTask: "RaisePurchaseRequisition",
          currentTaskOwner: "site-manager",
          initiatedDate: procurementProcess?.initiatedDate,
          siteManagerReview: {
            comment:procurementProcess?.siteManagerReview?.comment,
            approved:procurementProcess?.siteManagerReview?.approved,
             user: {
               ...procurementProcess?.siteManagerReview?.user,
               previousToken: '', 
             },
           },
          initiatedByUser: {
            ...procurementProcess?.initiatedByUser,  
            previousToken: '',  
          },
          requisition: {
            ...procurementProcess?.requisition,
            id: '',
            project:procurementProcess?.requisition.project,
            development:procurementProcess?.requisition.development,
            items: procurementProcess?.requisition?.items?.map((item) => ({
              ...item,
              quantity: {
                ...item.quantity,
                id: undefined,
              },
              unit:{
                 id:item.unit.id,
                 name:item.unit.name
              },
              id: undefined,
            })),
          },
        };
        console.log("+++++++++procurementProcessPartial++++++++",procurementProcessPartial);
        result = await updateProcurementProcess({ id: uuid, procurementProcessPartial }).unwrap();
      } else {
        newProcurementProcess = {
          currentTask: "SiteManagerReviewPurchaseRequisition",
          previousTask: "RaisePurchaseRequisition",
          currentTaskOwner: "site-manager",
          initiatedDate: new Date(),
          initiatedByUser: user,
          requisition: reqData,
          siteManagerReview: undefined,
        };
         console.log("newProcurementProcess",newProcurementProcess);
        result = await createProcurementProcessForm({ newProcurementProcess }).unwrap();
      }

      if (result) {
        console.log('ProcurementProcessForm submitted successfully');
        setShowOverlay(false);
        setShowSuccessMsg(true);
      }
    } catch (error) {
      setShowOverlay(false);
      console.error("Error submitting ProcurementProcessForm:", error);
    }
   };

  if (redirectToDashboard) {
    return <ProcurementProcessDashboard />;
  }

  return (
    <PageWithHeader>
      {showOverlay && <LoadingCover isSuccess={showSuccessMsg} />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              <CreateRequisitionForm />
              <Button
                variant="contained"
                type="submit"
              >
                {procurementProcess?.requisition ? 'Resubmit Corrected Requisition' : 'Raise Requisition'}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setRedirectToDashboard(true)}
              >
                Go to Dashboard
              </Button>
            </Stack>
          </Form>
        </LocalizationProvider>
      </Formik>
    </PageWithHeader>
  );
};

export default CreateRequisitionPage;