const createAccountModel = {
    formId: 'createAccountForm',
    formField: {
        createAccountName: {
            name: 'createAccountName',
            label: 'Name',
            required: true,
            requiredErrorMsg: 'Users name is required',
            invalidErrorMsg: 'Invalid name'
        },
        createAccountDepartment: {
            name: 'createAccountDepartment',
            label: 'Department',
            required: true,
            requiredErrorMsg: 'Department is required',
            invalidErrorMsg: 'Invalid department'
        },
        createAccountEmail: {
            name: 'createAccountEmail',
            label: 'Email address',
            required: true,
            requiredErrorMsg: 'Email address is required',
            invalidErrorMsg: 'Invalid email address'
        },
        createAccountPass: {
            name: 'createAccountPass',
            label: 'Password',
            required: true,
            requiredErrorMsg: 'Password is required',
            invalidErrorMsg: 'Password must be at least 8 characters'
        },
        createAccountGender: {
            name: 'createAccountGender',
            label: 'Gender',
            required: true,
            requiredErrorMsg: 'Gender is required',
            invalidErrorMsg: 'Gender is invalid'
        },
    },
    initialValues: {
        createAccountName: '',
        createdAccountDepartment: '',
        createAccountEmail: '',
        createAccountPass: '',
        createAccountGender: '' 
    }
};

export type createAccountModelType = {
    createAccountName: string;
    createdAccountDepartment: string;
    createAccountEmail: string;
    createAccountPass: string;
    createAccountGender: string; 
}

export default createAccountModel
