import { createSlice } from '@reduxjs/toolkit';

interface UIState {
  actionNotification: {
    show: boolean,
    notification: {
      title: string,
      description: string,
    }
  } | null
}

const initialState: UIState = {
  actionNotification: null,
}

const ui = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    addActionNotification: (state, action) => {
      state.actionNotification = action.payload;
    },
    removeActionNotification: (state) => {
      state.actionNotification = null;
    },
  },
});

export default ui;
