import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Procurement from "./ProcurementDashboard";
import CreateRequisitionPage from "./CreateRequisitionPage";
import ViewRequisition from "./ViewRequisition";
import DevelopmentsPage from "./DevelopmentsPage";
import UnitsPage from "./UnitsPage";
import BillOfMaterialsPage from './BillOfMaterialsPage';

import CreateRequisitionReviewPage from './CreateRequisitionReviewPage';
import ProcurementProcess from './ProcurementProcessDashboard';
import CreateStakeholderPage from '../pages/CreateStakeholderPage';

const ProcurementRoutes = () => {
  return (
    <Switch>
      <Route exact path="/procurement" component={ProcurementProcess} />
      <Route exact path="/procurement/create-requisition" component={CreateRequisitionPage} />
      <Route exact path="/procurement/RaisePurchaseRequisition/:uuid" component={CreateRequisitionPage} />
      <Route exact path="/procurement/CorrectPurchaseRequisition/:uuid" component={CreateRequisitionPage} />
      <Route exact path="/procurement/create-procurement-request" component={CreateRequisitionPage} />
      <Route exact path="/procurement/SiteManagerReviewPurchaseRequisition/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/CPOReviewPurchaseRequisition/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/CPOPicksSupplier/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/PCToUploadQuotations/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/PMToReviewProcurementApplication/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/ProChairReviewsSupplierChoice/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/PMToIssuePurchaseOrder/:uuid" component={ViewRequisition} />
      <Route exact path="/procurement/developments" component={DevelopmentsPage} />
      <Route exact path="/procurement/units" component={UnitsPage} />
      <Route exact path="/procurement/bom" component={BillOfMaterialsPage} />
      <Route exact path="/procurement/create-requisition-review" component={CreateRequisitionReviewPage} />
      <Route exact path="/procurement/procurement-process-dashboard" component={ProcurementProcess} />
      <Route exact path="/procurement/stakeholders" component={CreateStakeholderPage} />

    </Switch>
  );
}

export default ProcurementRoutes;
