import {useDispatch, useSelector, TypedUseSelectorHook} from "react-redux";
import type { RootState, AppDispatch } from './store'
import {useMemo} from 'react'
import { selectCurrentUser } from './reducers/authSlice'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector


export const useAuth = () => {
  const user = useSelector(selectCurrentUser)
  return useMemo(() => ({ user }), [user])
}
