import React from 'react';
import {CircularProgress, MenuItem, Stack, TextField, Typography} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import {useGetDevelopmentsProjectsQuery} from "../../redux/vmsApi";
import createUnitModel from "../forms/models/createUnitModel";

const {
  formField: {
    unitName,
    project,
  },
} = createUnitModel;

const CreatUnitForm: React.FC = () => {
  const { errors, touched, setFieldValue } = useFormikContext<any>();

  const { data: projects = [], isLoading } = useGetDevelopmentsProjectsQuery();

  if (isLoading) {
    return (
      <CircularProgress/>
    )
  }

  return (
    <Stack width="100%" spacing={5}>
      <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Add Unit
      </Typography>
      <Stack direction={'row'} spacing={2}>
        <Field
          as={TextField}
          fullWidth
          {...unitName}
          error={touched[unitName.name] && Boolean(errors[unitName.name])}
          helperText={touched[unitName.name] && errors[unitName.name] ? errors[unitName.name] : ''}
        />

        <Field
          as={TextField}
          select
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(`project`, e.target.value)}
          {...project}
          error={touched[project.name] && Boolean(errors[project.name])}
          helperText={touched[project.name] && errors[project.name] ? errors[project.name] : ''}
          fullWidth
        >
          {projects.map(project => (
            <MenuItem key={project.projectId} value={project.projectId}>
              {project.name}
            </MenuItem>
          ))}
        </Field>
      </Stack>
    </Stack>
  );
};

export default CreatUnitForm;
