import React, {useEffect, useState} from 'react';
import {Stack, Button} from "@mui/material";
import AddVisitorForm from '../forms/AddVisitorForm';
import {
  Formik,
  Form
} from 'formik';
import addVisitorModel , {addVisitorModelType} from '../forms/models/addVisitorModel';
import addVisitorSchema from '../forms/schemas/addVisitorSchema';
import * as Yup from 'yup';
import {InviteData, usePostAddInviteMutation} from '../../redux/vmsApi'
import LoadingCover from '../common/LoadingCover'

type MyFormValues = addVisitorModelType

const addVisitorModelInitial = addVisitorModel.initialValues

const AddVisitorFormSchema = Yup.object().shape({...addVisitorSchema})

type Props = {
  onSuccess?: (invite: InviteData) => void;
};

const AddVisitor: React.FC<Props> = ({ onSuccess }) => {
  const [createInvite, {isSuccess, isLoading}] = usePostAddInviteMutation();
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => setShowOverlay(false), 3000); // hide after 3 seconds
    }
  }, [isLoading, isSuccess]);

  const initialValues:MyFormValues = {
    ...addVisitorModelInitial,
  };

  const handleSubmit = async (values: MyFormValues) => {
    const data = {
      isPermanentVisitor: values.isPermanentVisitor,
      visitorName: values.visitorName,
      visitorEmail: values.visitorEmail,
      visitorWhatsApp: values.visitorWhatsApp,
      meetingWith: values.meetingWith ?? '',
      meetingDateTime: values.meetingDateTime ?? '',
      idNumber: values.idNumber ?? '',
    } as InviteData;
    const result = await createInvite({inviteData: data});
    if ('data' in result && result.data && onSuccess) {
      onSuccess(result.data);
    } else if ('error' in result) {
      console.error("Error creating invite:", result.error);
    }
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess}/>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={AddVisitorFormSchema}
      >
        <Form>
          <Stack spacing={4} width="100%" pt={4}>
            <AddVisitorForm />
            <Button
              variant="contained"
              type="submit"
              data-cy={"createAccountSubmit"}
            >
              Create
            </Button>
          </Stack>
        </Form>
      </Formik>
    </React.Fragment>
  )
}

export default AddVisitor;
