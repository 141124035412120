import React from 'react';
import PageWithHeader from "../common/PageWithHeader";
import {Card, CardContent, Grid, Paper, Typography} from '@mui/material';
import {makeStyles} from "@mui/styles";
import {Link, useRouteMatch} from 'react-router-dom';
import {AppRegistration, AttachMoney, Home, PeopleAlt} from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  card: {
    height: '230px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1976d2',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: '0.3s',
    borderRadius: '15px',
    '&:hover': {
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    },
  },
  title: {
    textAlign: 'center',
    color: '#fff',
  },
  link: {
    textDecoration: 'none', // Remove underline
    color: 'inherit', // Inherit text color
    display: 'block' // Make the link take the full height of the grid item
  },
  icon: {
    fontSize: '60px',
    marginBottom: '10px',
    color: '#fff',
  },
}));

const PropertyRegisterDashboard: React.FC = () => {
  const classes = useStyles();
  const {url} = useRouteMatch();

  const options = [
    {id: 1, title: 'Log Payment', link: `${url}/add-payment`, icon: <AttachMoney className={classes.icon}/>},
    {id: 2, title: 'Clients', link: `${url}/clients`, icon: <PeopleAlt className={classes.icon}/>},
    {id: 3, title: 'Properties', link: `${url}/properties`, icon: <Home className={classes.icon}/>},
    {id: 4, title: 'View Register', link: `${url}/view`, icon: <AppRegistration className={classes.icon}/>},
  ];

  return (
    <PageWithHeader>
      <Paper elevation={2} sx={{margin: 10, padding: 5, borderRadius: '15px'}}>
        <Typography variant='h1' gutterBottom style={{fontWeight: 'bold', color: '#123456'}}>
          Property Register Dashboard
        </Typography>
        <Typography variant='body1' gutterBottom>
          Welcome to the Property Register. This central hub allows you to efficiently manage property-related
          tasks, including logging payments, tracking client interactions, and maintaining property records. Use the
          following tools to streamline your property management workflow and ensure all entries are up-to-date and
          accurate.
        </Typography>

        <Grid container spacing={3} pt={3}>
          {options.map((option) => (
            <Grid item xs={6} sm={3} md={3} key={option.id}>
              <Link to={option.link} className={classes.link}>
                <Card className={classes.card}>
                  <CardContent style={{textAlign: 'center'}}>
                    {option.icon}
                    <Typography variant="h6" className={classes.title}>{option.title}</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </PageWithHeader>
  );
}

export default PropertyRegisterDashboard;
