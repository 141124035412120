import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Button,
  Stack
} from "@mui/material";
import {InviteWithRelations} from "../../redux/vmsApi";
import {isSameDay, isWithinInterval} from "date-fns";
import config from "../../config";

interface OwnProps {
  showCard?: boolean;
  showBanner?: boolean;
  showDownloadBtn?: boolean;
  showAddAnotherInviteBtn?: boolean;
  invite: InviteWithRelations | undefined;
  addAnotherInviteCallback?: () => void;
  captureId?: boolean;
}

const InviteDetails: React.FC<OwnProps> = (props) => {
  const {
    showCard = true,
    showBanner = true,
    showDownloadBtn = false,
    showAddAnotherInviteBtn = false,
    invite,
    addAnotherInviteCallback
  } = props;

  if (!invite) {
    return (
      <Card raised={showCard} sx={{borderRadius: '20px', margin: 2}}>
        <CardContent>
          <Typography variant="body1" style={{textAlign: 'center'}}>No invite data available.</Typography>
        </CardContent>
      </Card>
    );
  }

  const showMeetingDateTime = invite && !invite.isPermanentVisitor;
  let isDateValid = false;
  let alreadyScanned = !!invite.scanned;
  let formattedMeetingDateTime = '';

  if (invite.meetingDateTime && showMeetingDateTime) {
    const meetingDateTime = new Date(invite.meetingDateTime);
    formattedMeetingDateTime = meetingDateTime.toLocaleString('en-GB', {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

    const now = new Date();
    const isToday = isSameDay(meetingDateTime, now);
    const isValidTime = isWithinInterval(now, {
      start: new Date(meetingDateTime.getTime() - 60 * 120 * 1000),
      end: new Date(meetingDateTime.getTime() + 60 * 120 * 1000),
    });

    isDateValid = isToday && isValidTime;
  }

  const getBannerDetails = () => {
    if (invite.isPermanentVisitor) {
      return { backgroundColor: 'green', text: 'Permanent Visitor' };
    }
    if (isDateValid) {
      if (alreadyScanned) {
        return { backgroundColor: 'orange', text: 'Invite has already been scanned' };
      }
      return { backgroundColor: 'green', text: 'Invite is valid for entry' };
    }
    return { backgroundColor: 'red', text: 'Invite is not valid for entry' };
  };

  const bannerDetails = getBannerDetails();

  return (
    <React.Fragment>
      {showBanner &&
        <Box sx={{
          backgroundColor: bannerDetails.backgroundColor,
          color: 'white',
          padding: 1,
          textAlign: 'center',
          marginBottom: 2
        }}>
          <Typography sx={{color: 'white'}}>
            {bannerDetails.text}
          </Typography>
        </Box>
      }
      <List>
        <ListItem>
          <ListItemText primary="Visitor Name" secondary={invite.visitorName || 'N/A'}/>
        </ListItem>
        <Divider component="li"/>
        <ListItem>
          <ListItemText primary="Visitor Email" secondary={invite.visitorEmail || 'N/A'}/>
        </ListItem>
        <Divider component="li"/>
        <ListItem>
          <ListItemText primary="Visitor WhatsApp" secondary={invite.visitorWhatsApp || 'N/A'}/>
        </ListItem>
        <Divider component="li"/>
        <ListItem>
          <ListItemText primary="Meeting With" secondary={invite.meetingWith || 'N/A'}/>
        </ListItem>
        <Divider component="li"/>
        <ListItem>
          <ListItemText primary="Meeting Date & Time" secondary={formattedMeetingDateTime || 'N/A'}/>
        </ListItem>
        <Divider component="li"/>
        <ListItem>
          <ListItemText primary="Meeting Created By" secondary={invite.meetingCreatedBy ?? 'Not found'}/>
        </ListItem>
        <Divider component="li"/>
        <ListItem>
          <ListItemText primary="Visitor ID Number" secondary={invite.idNumber ?? 'Not found'}/>
        </ListItem>
      </List>
      <Stack direction={'row'} spacing={4}>
        {showAddAnotherInviteBtn && addAnotherInviteCallback &&
          <Button variant="text" color="primary" onClick={() => addAnotherInviteCallback()}>
            Create Another
          </Button>
        }
        {showDownloadBtn &&
          <Button variant="contained" color="primary">
            <a href={`${config.CDN_URL}/invites/${invite.id}.jpeg`}
               target="_blank"
               rel="noreferrer"
               download
               style={{textDecoration: 'none', color: 'inherit'}}>
              Download Pass
            </a>
          </Button>
        }
      </Stack>
    </React.Fragment>
  );
};

export default InviteDetails;
