import React from 'react';
import {
  Box,
  Paper
} from '@mui/material';
import {
  useGetUsersWithReviewsQuery, User
} from "../../redux/vmsApi";
import PageWithHeader from "../common/PageWithHeader";
import EmployeeCard from "../common/EmployeeCard";

interface OwnProps {
  match?: any;
}

const EmployeeReviewAdmin: React.FC<OwnProps> = ({ match }) => {
  const { data: usersData, isError: usersIsError, isLoading: usersIsLoading } = useGetUsersWithReviewsQuery();

  return (
    <PageWithHeader>
      <Box padding={10}>
        <Paper elevation={3} sx={{ padding: 10 }}>
          {usersData && usersData.map((user: User) => (
            <EmployeeCard userData={user} />
          ))}
        </Paper>
      </Box>
    </PageWithHeader>
  );
};

export default EmployeeReviewAdmin;
