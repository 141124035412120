import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import { ChartOptions } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

interface OwnProps {
  data: {
    current: number[];
    desired: number[];
  }
}

const LifeWheelVisualisation: React.FC<OwnProps> = (props) => {
  const data = {
    labels: [
      'Church & God',
      'Marriage / Relationship',
      'Children',
      'Family',
      'Personal Finance',
      'Personal Growth',
      'Career Work',
      'Health / Exercise',
      'Reading / Hobbies',
      'Friends',
      'Community',
      'Philanthropy'
    ],
    datasets: [
      {
        label: 'Current',
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: props.data.current
      },
      {
        label: 'Desired',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: 'rgba(255,99,132,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255,99,132,1)',
        data: props.data.desired
      }
    ]
  };

  const options: ChartOptions<'radar'> = {
    scales: {
      r: {
        angleLines: {
          display: false
        },
        suggestedMin: 0,
        suggestedMax: 10
      }
    }
  };

  return <Radar data={data} options={options} />;
};

export default LifeWheelVisualisation;
