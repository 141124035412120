import * as Yup from 'yup';
import createAccountModel from '../models/createAccountModel';

const {
  formField: {
    createAccountName,
    createAccountDepartment,
    createAccountGender,
    createAccountEmail,
    createAccountPass
  }
} = createAccountModel;

const createAccountSchema = {
    [createAccountName.name]: Yup.string()
        .required(`${createAccountName.requiredErrorMsg}`),
    [createAccountDepartment.name]: Yup.string()
      .required(`${createAccountDepartment.requiredErrorMsg}`),
    [createAccountGender.name]: Yup.string()
      .required(`${createAccountGender.requiredErrorMsg}`),
    [createAccountEmail.name]: Yup.string()
        .required(`${createAccountEmail.requiredErrorMsg}`)
        .email(`${createAccountEmail.invalidErrorMsg}`),
    [createAccountPass.name]: Yup.string()
        .required(`${createAccountPass.requiredErrorMsg}`)
        .min(8, `${createAccountPass.invalidErrorMsg}`)
  };

export default createAccountSchema