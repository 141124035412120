import React from 'react';
import {Box, CircularProgress, Paper, Typography} from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import PageWithHeader from "../common/PageWithHeader";
import {useGetClientsQuery, useGetPropertiesQuery} from "../../redux/vmsApi";
import AddPropertyForm from "./AddPropertyForm";

const Properties: React.FC = () => {
  const {data: properties, isLoading} = useGetPropertiesQuery({});

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'development', headerName: 'Development', width: 150 },
    { field: 'phase', headerName: 'Phase', width: 150 },
    { field: 'unitNumber', headerName: 'Unit Number', width: 100 },
    {
      field: 'clientName',
      headerName: 'Client Name',
      width: 250,
      // valueGetter: (value, row) => {
      //   return row.client?.clientName || 'No Client'
      // },
    },
  ];

  return (
    <PageWithHeader padding={5}>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Property Management
      </Typography>
      <Paper sx={{my: 5}}>
        <AddPropertyForm />
      </Paper>
      <Paper sx={{my: 5, p: 5}}>
        <Typography variant='h2' gutterBottom>
          Existing Properties
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height={400}>
            <CircularProgress />
          </Box>
        ) : (
            <DataGrid
              rows={properties || []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[10, 10]}
              checkboxSelection
            />
        )}
      </Paper>
    </PageWithHeader>
  )
}

export default Properties;