export const generatePastQuarters = () => {
  const quarters = [];
  const currentDate = new Date();
  let month = currentDate.getMonth();
  const year = currentDate.getFullYear();

  for (let i = 0; i < 3; i++) {
    if (month < 0) {
      month += 12;
    }
    const quarter = Math.floor(month / 3);
    const quarterLabel = `Q${quarter + 1} ${year - (quarter === 3 ? 1 : 0)}`;
    quarters.push(quarterLabel);
    month -= 3;
  }
  return quarters;
};

export const generatePastMonths = () => {
  const months = [];
  const currentDate = new Date();
  let month = currentDate.getMonth();
  let year = currentDate.getFullYear();

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  for (let i = 0; i < 3; i++) {
    if (month < 0) {
      month += 12;
      year -= 1;
    }
    const monthLabel = `${monthNames[month]} ${year}`;
    months.push(monthLabel);
    month -= 1;
  }
  return months;
};