import React from 'react';
import {Box} from "@mui/material";
import Header from './Header';

interface OwnProps {
  children: React.ReactNode;
  bgColor?: string;
  padding?: number;
}

const PageWithHeader = (props: OwnProps) => {
  const {children, bgColor, padding=0} = props;
  return (
    <Box sx={{height: '100vh', backgroundColor: bgColor, margin: 0, padding: 0}}>
      <Header/>
      <Box sx={{padding: padding}}>
        {children}
      </Box>
    </Box>
  );
}

export default PageWithHeader;
