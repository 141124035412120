const developmentFormModel = {
  formId: 'developmentForm',
  formField: {
    name: {
      name: 'name',
      label: 'Name',
      required: true,
      requiredErrorMsg: 'Name is required',
    },
    description: {
      name: 'description',
      label: 'Description',
      required: false,
    },
    projects: {
      name: 'projects',
      label: 'Project',
      required: false,
      fields: {
        projectName: {
          name: 'projectName',
          label: 'Project Name',
          required: true,
          requiredErrorMsg: 'Project Name is required',
        },
        projectDescription: {
          name: 'projectDescription',
          label: 'Project Description',
          required: true,
          requiredErrorMsg: 'Project Description is required',
        },
        projectStatus: {
          name: 'projectStatus',
          label: 'Status',
          required: true,
          requiredErrorMsg: 'Status is required',
        },
        projectStartDate: {
          name: 'projectStartDate',
          label: 'Project Start Date',
          required: true,
          requiredErrorMsg: 'Project Start Date is required',
        }
      }
    }
  },
  initialValues: {
    name: '',
    description: '',
    projects: [
      {
        projectName: '',
        projectDescription: '',
        projectStatus: '',
        projectStartDate: new Date(),
      }
    ]
  }
};

export type ProjectType = {
  projectName: string;
  projectDescription: string;
  projectStatus: string;
  projectStartDate: Date;
};

export type DevelopmentType = {
  name: string;
  description: string;
  projects: ProjectType[];
};

export default developmentFormModel;
