import React, { ErrorInfo } from "react";
import { Grid, Typography } from "@mui/material";

class VmsError extends React.Component<React.HTMLAttributes<HTMLDivElement>, {}> {

  state = {
    error: '',
    errorInfo: '',
    hasError: false
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      error: error,
      hasError: true
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
    console.log('error: ', error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }} item md={12}  >
          <Typography variant="h1" align={"center"}>Whoops!</Typography>
          <Typography variant="h2" align={"center"}>Something went wrong!</Typography>
          <Typography align={"center"} style={{ marginTop: '10px'}}>
            We have automatically logged an issue with our team and we are working on resolving the problem.
          </Typography>
          <Typography align={"center"} style={{ marginTop: '10px'}}>
            Please refresh the page to see if the problem persists.
          </Typography>
        </Grid>
      )
    }


    return this.props.children;
  }
}

export default VmsError;
