import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import Unauthorised from "./Unauthorised";

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  roles: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, roles, ...rest }) => {
  const user = useAppSelector(state => state.auth.user);

  return (
    <Route
      {...rest}
      render={props => {
        if (!user) {
          // Not logged in, redirect to login page
          return <Redirect to="/" />;
        }

        if (roles && roles.length > 0 && !roles.some(role => user.roles && user.roles.includes(role))) {
          // User doesn't have required role, show unauthorized message
          return <Unauthorised/>;
        }

        // User has required role, render the component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
