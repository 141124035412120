import React, { useState } from 'react';
import {
  Button,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Autocomplete, InputAdornment, Paper,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import PageWithHeader from "../common/PageWithHeader";
import { useAppSelector } from "../../redux/hooks";

interface PaymentFormValues {
  client: string;
  property: string;
  depositorName: string;
  depositorId: string;
  amount: number;
  currency: string;
}

const initialValues: PaymentFormValues = {
  client: '',
  property: '',
  depositorName: '',
  depositorId: '',
  amount: 0,
  currency: 'USD',
};

const validationSchema = Yup.object({
  client: Yup.string().required('Client is required'),
  property: Yup.string().required('Property is required'),
  depositorName: Yup.string().when('client', {
    is: (client: string) => client !== '',
    then: Yup.string().optional(),
    otherwise: Yup.string().required('Depositor Name is required'),
  }),
  depositorId: Yup.string().when('client', {
    is: (client: string) => client !== '',
    then: Yup.string().optional(),
    otherwise: Yup.string().required('Depositor ID is required'),
  }),
  amount: Yup.number().required('Amount is required'),
  currency: Yup.string().required('Currency is required'),
});

const AddPaymentForm: React.FC = () => {
  const currentUser = useAppSelector(state => state.auth.user);
  const clients: readonly any[] = []
  const properties: readonly any[] = []
  const [selectedClient, setSelectedClient] = useState<string>('');

  const clientProperties = properties?.filter(property => property.clientId === selectedClient);

  return (
    <PageWithHeader>
      <Paper elevation={2} sx={{ margin: 10, padding: 5, borderRadius: '15px' }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            // Handle form submission
            console.log({
              ...values,
              loggedBy: currentUser?.name,
            });
            resetForm();
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Stack spacing={4} width="100%">
                <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                  Add Payment
                </Typography>
                <Autocomplete
                  options={clients || []}
                  getOptionLabel={(client) => client.name}
                  onChange={(_, value) => {
                    setSelectedClient(value?.id || '');
                    setFieldValue('client', value?.id || '');
                    setFieldValue('property', value?.properties.length === 1 ? value.properties[0].id : '');
                  }}
                  renderInput={(params) => (
                    <Field
                      component={TextField}
                      {...params}
                      name="client"
                      label="Select Client"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  )}
                />
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel>Property</InputLabel>
                  <Field
                    name="property"
                    as={Select}
                    label="Property"
                    disabled={!selectedClient || clientProperties?.length === 1}
                  >
                    {clientProperties?.map((property: any) => (
                      <MenuItem key={property.id} value={property.id}>
                        {property.name}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <Field
                  name="depositorName"
                  as={TextField}
                  label="Depositor Name"
                  variant="outlined"
                  fullWidth
                  required={values.client === ''}
                  disabled={values.client !== ''}
                />
                <Field
                  name="depositorId"
                  as={TextField}
                  label="Depositor ID"
                  variant="outlined"
                  fullWidth
                  required={values.client === ''}
                  disabled={values.client !== ''}
                />
                <Field
                  name="amount"
                  as={TextField}
                  label="Amount"
                  type="number"
                  variant="outlined"
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                  }}
                />
                <Button variant="contained" type="submit" sx={{ mt: 4 }}>
                  Submit
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Paper>
    </PageWithHeader>
  );
};

export default AddPaymentForm;
