import React, { ReactNode } from 'react';
import { Drawer, List, ListItem, ListItemText, Toolbar, CssBaseline, AppBar, Box, IconButton, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';

const drawerWidth = 240;

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  toolbarLeft: {
    flexGrow: 1,
  },
  toolbarRight: {
    display: 'flex',
    alignItems: 'center',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#1C4697', // Sidebar background color
  },
  drawerContainer: {
    overflow: 'auto',
    marginTop: '20px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItemText: {
    color: 'white',
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

interface ProcurementContainerProps {
  children: ReactNode;
}

const ProcurementContainer: React.FC<ProcurementContainerProps> = ({ children }) => {
  const classes = useStyles();
  const user = useAppSelector(state => state.auth.user);

  const menuItems = [
    { text: 'Dashboard', path: '/procurement/procurement-process-dashboard' },
    { text: 'Requisitions', path: '#' },
    { text: 'Purchase Orders', path: '#' },
    { text: 'Stakeholders', path: '/procurement/stakeholders' },
    { text: 'Developments', path: '/procurement/developments' },
    { text: 'Units', path: '/procurement/units' },
    { text: 'Bill of Materials', path: '/procurement/bom' },
    { text: 'Reports', path: '#' },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img
            alt="WestProp Logo"
            src="https://staging.westprop.com/wp-content/uploads/2023/08/WestProp-Logo-White.png"
            style={{ marginRight: 20, width: 150 }}
          />
          <div className={classes.toolbarRight}>
            <IconButton color="inherit">
              <NotificationsIcon sx={{color: '#1C4697'}} />
            </IconButton>
            <IconButton sx={{ p: 0 }}>
              <Avatar>{user?.name?.slice(0, 1) ?? ''}</Avatar>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {menuItems.map((item) => (
              <ListItem button component={Link} to={item.path} key={item.text} className={classes.link}>
                <ListItemText primary={item.text} classes={{ primary: classes.listItemText }} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Box mx={'20px'}>
          {children}
        </Box>
      </main>
    </div>
  );
};

export default ProcurementContainer;
