import React, { useState } from 'react';
import { Button, Stack, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch } from '../../redux/hooks';
import { usePostClientMutation } from '../../redux/vmsApi';
import {push} from "connected-react-router/immutable";

interface AddClientFormValues {
  clientName: string;
  clientIdNumber: string;
  clientEmail: string;
}

const initialValues: AddClientFormValues = {
  clientName: '',
  clientIdNumber: '',
  clientEmail: '',
};

const validationSchema = Yup.object({
  clientName: Yup.string().required("The client's name is required"),
  clientIdNumber: Yup.string().required("The client's ID Number is required"),
  clientEmail: Yup.string().email("Invalid email format").required("The client's email is required"),
});

const AddClientForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [postClient] = usePostClientMutation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (values: AddClientFormValues, { resetForm }: { resetForm: () => void }) => {
    try {
      const response = await postClient({clientData: values}).unwrap();
      setIsSubmitted(true);
      resetForm();
    } catch (error) {
      console.error('There was an error submitting the form', error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <Stack spacing={4} width="100%" p={5}>
            {!isSubmitted ? (
              <>
                <Typography variant='h2' gutterBottom>
                  Add Client
                </Typography>
                <Field
                  name="clientName"
                  as={TextField}
                  label="Client Name"
                  variant="outlined"
                  fullWidth
                  required
                />
                <Field
                  name="clientIdNumber"
                  as={TextField}
                  label="Client ID"
                  variant="outlined"
                  fullWidth
                  required
                />
                <Field
                  name="clientEmail"
                  as={TextField}
                  label="Client Email"
                  variant="outlined"
                  fullWidth
                  required
                />
                <Button variant="contained" type="submit" sx={{ mt: 4 }}>
                  Add Client
                </Button>
              </>
            ) : (
              <>
                <Typography variant='h5' gutterBottom style={{ color: '#123456' }}>
                  Client Added Successfully!
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      disabled
                    />
                  }
                  label="Thank you for adding a new client."
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsSubmitted(false)}
                >
                  Add Another
                </Button>
              </>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default AddClientForm;
