import React, { useState, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import { Box, Paper, Typography } from '@mui/material';

const QRCodeScanner: React.FC<{ onScan: (data: string) => void }> = ({ onScan }) => {
  const [error, setError] = useState<string | null>(null);
  const [isScannerActive, setIsScannerActive] = useState(true);

  const handleScan = (data: string | null) => {
    if (data) {
      onScan(data);
      setIsScannerActive(false); // Deactivate scanner after a successful scan
    }
  };

  const handleError = (err: any) => {
    setError(err.toString());
  };

  useEffect(() => {
    // Reactivate the scanner when needed
    if (!isScannerActive) {
      setIsScannerActive(true);
    }
  }, [isScannerActive]);

  return (
    <Box sx={{
      position: 'relative',
      maxWidth: 500,
      width: '100%',
      margin: 'auto'
    }}>
      {isScannerActive && (
        <QrReader
          onResult={(result, error) => {
            if (result) {
              handleScan(result?.getText());
            }
            if (error) {
              handleError(error);
            }
          }}
          constraints={{ facingMode: 'environment' }}
          containerStyle={{ width: '100%', height: 'auto' }}
          videoStyle={{ width: '100%', height: 'auto', transform: 'scaleX(-1)' }} // Mirroring the video
        />
      )}
      {error !== 'e' && (
        <Paper elevation={1} sx={{
          position: 'absolute',
          bottom: 10,
          left: '50%',
          transform: 'translateX(-50%)',
          padding: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.8)'
        }}>
          <Typography variant="body2" color="error">Error: {error}</Typography>
        </Paper>
      )}
    </Box>
  );
};

export default QRCodeScanner;
