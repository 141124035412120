import { createBrowserHistory } from 'history';
import {connectRouter, routerMiddleware} from "connected-react-router";
import { configureStore, Reducer } from '@reduxjs/toolkit'
import { vmsApi } from './vmsApi'
import authSlice from "./reducers/authSlice";
import ui from "./reducers/uiReducer";

export const history = createBrowserHistory();

const store = configureStore({
  reducer: {
    router: connectRouter(history) as Reducer,
    auth: authSlice.reducer,
    ui: ui.reducer,
    [vmsApi.reducerPath]: vmsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: vmsApi,
      },
      immutableCheck: false,
      serializableCheck: false,
    }).prepend(
      routerMiddleware(history)
    ).concat(vmsApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store;
