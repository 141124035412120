import React from 'react';
import {Box, CircularProgress} from "@mui/material";
import { styled } from '@mui/material/styles';
import Check from "@mui/icons-material/Check";

const LoadingContainer = styled(Box)(({ theme }) => ({
  position: 'fixed', // Changed from 'absolute' to 'fixed'
  zIndex: 20,
  height: '100vh', // Ensures full viewport height coverage
  width: '100vw', // Ensures full viewport width coverage
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  '&:before': {
    content: '""',
    zIndex: -1,
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'white',
    opacity: 0.8,
  },
}));

interface OwnProps {
  isSuccess?: boolean;
  successComponent?: React.ReactNode;
  background?: string;
  position?: string;
}

const LoadingCover = (props: OwnProps) => {
  const { isSuccess, successComponent = null, background } = props;
  return (
    <LoadingContainer sx={{ background }}>
      {isSuccess ? (
        successComponent ? (
          successComponent
        ) : (
          <Check color="primary" sx={{ fontSize: '40px' }} data-cy="loading-success" />
        )
      ) : (
        <CircularProgress />
      )}
    </LoadingContainer>
  );
};

export default LoadingCover;
