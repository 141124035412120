import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Snackbar, SnackbarCloseReason, SnackbarOrigin, Typography } from '@mui/material';
import {Alert} from "@mui/material";
import {NotificationImportant} from "@mui/icons-material";

interface INotificationProps {
  title: string;
  message: string;
  open: boolean;
  onClose: (event: Event | SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void;
  position?: SnackbarOrigin;
}

export interface INotification {
  title: string;
  message: string;
}

const Notification = ({ title, message, open, onClose, position }: INotificationProps) => {
  const [notification, setNotification] = useState<INotification>();

  useEffect(() => {
    setNotification({ title, message });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Snackbar
      anchorOrigin={!!position ? position : { vertical: 'top', horizontal: 'left'}}
      open={open}
      autoHideDuration={7000}
      onClose={onClose}>
      <Alert
        elevation={6}
        variant='filled'
        severity="info"
        icon={<NotificationImportant />}>
        {!!notification && <Typography sx={{color: 'white'}}>{ title }</Typography>}
        {!!notification && <Typography sx={{color: 'white'}}>{ message }</Typography>}
      </Alert>
    </Snackbar>
  );
}

export default Notification;