import { useEffect, useState } from 'react';
import axios from 'axios';

const useCountries = () => {
  const [countries, setCountries] = useState<string[]>([]);

  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countryNames = response.data.map((country: any) => country.name.common);
        setCountries(countryNames);
      })
      .catch(error => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  return countries;
};

export default useCountries;
