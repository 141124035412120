import React from 'react';
import {Dialog, Slide} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { styled } from '@mui/material/styles';
import useIsMobile from '../../hooks/useIsMobile';
import authSlice from "../../redux/reducers/authSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import AuthenticationContainer from './AuthenticationContainer';
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialogue = styled(Dialog)(({ theme }) => ({
    transition: 'all 0.3s ease',
    '& .MuiDialogTitle-root': {
        textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
        '& .MuiDialog-paper': {
            position: 'absolute',
            bottom: '0px',
            height: 'auto',
            borderRadius: '10px 10px 0px 0px'
        }
    }
}));

const LoginModal: React.FC = () => {
    const isMobile = useIsMobile()
    const dispatch = useAppDispatch();

    const closeLoginModal = () => {
        dispatch(authSlice.actions.closeLoginModal())
    }
    const loginModalOpen = useAppSelector(state => state.auth.loginModalOpen);

    return (
        <StyledDialogue
            fullScreen={isMobile}
            open={loginModalOpen}
            onClose={() => closeLoginModal()}
            TransitionComponent={Transition}
            fullWidth
            maxWidth={'xs'}
            data-cy="authentication-dialogue"
        >
            <AuthenticationContainer closeLoginModal={closeLoginModal} />
        </StyledDialogue>
    );
}

export default LoginModal;
