import React, {useEffect} from 'react';
import {Stack, TextField} from '@mui/material';
import logInModel from './models/logInModel'
import {Field, useFormikContext} from "formik";
import { styles } from '../../theme/theme';

const {formField: {logInEmail, logInPass}} = logInModel;

interface OwnProps {
  apiError?: string
}

const LoginFields = (props: OwnProps) => {
  const {errors, touched, setErrors} = useFormikContext<any>();

  useEffect(() => {
    setErrors({[logInPass.name]: props.apiError})
  }, [props.apiError, setErrors])

  return (
  <Stack width="100%" spacing={4} pt={4}>

    <Field
    as={TextField}
    variant={styles.textFieldVariant}
    fullWidth
    data-cy={logInEmail.name}
    name={logInEmail.name}
    label={logInEmail.label}
    required={logInEmail.required}
    inputProps={{required: false}}
    type={logInEmail.inputType}
    error={Boolean(errors[logInEmail.name])}
    helperText={errors[logInEmail.name] ? errors[logInEmail.name] : ''}
    />

    <Field
    as={TextField}
    variant={styles.textFieldVariant}
    fullWidth
    data-cy={logInPass.name}
    name={logInPass.name}
    label={logInPass.label}
    required={logInPass.required}
    inputProps={{required: false}}
    type={logInPass.inputType}
    error={touched[logInPass.name] && Boolean(errors[logInPass.name])}
    helperText={(touched[logInPass.name] && errors[logInPass.name]) ? errors[logInPass.name] : ''}

    />
  </Stack>
  )
}

export default LoginFields
