import * as Yup from 'yup';
import employeeReviewModel from '../models/employeeReviewModel';

const {
  formField: {
    reviewPeriod,
    accomplishments,
    quarterlyGoals,
    monthlyGoals,
    areaToImprove,
    examplesOfExcellence,
    mainGoalToAchieve,
    turnUpOnTime,
    dailyCheckIn,
    reportsOnTime,
    quarterlyObjective
  }
} = employeeReviewModel;

const PerformanceItemSchema = Yup.object().shape({
  ranking: Yup.number(),
  title: Yup.string().required('Title is required'),
  rating: Yup.string(),
});

const employeeReviewSchema = Yup.object().shape({
  [reviewPeriod.name]: Yup.string().required('Review period is required'),
  [accomplishments.name]: Yup.array().of(PerformanceItemSchema),
  [quarterlyGoals.name]: Yup.array().of(PerformanceItemSchema),
  [monthlyGoals.name]: Yup.array().of(PerformanceItemSchema),
  [areaToImprove.name]: Yup.string().required('Area to improve is required'),
  [examplesOfExcellence.name]: Yup.string().required('Examples of excellence are required'),
  [mainGoalToAchieve.name]: Yup.string().required('Main goal to achieve is required'),
  [quarterlyObjective.name]: Yup.string().required('Quarterly objective is required'),
  [turnUpOnTime.name]: Yup.boolean(),
  [dailyCheckIn.name]: Yup.boolean(),
  [reportsOnTime.name]: Yup.boolean(),
});


export default employeeReviewSchema;
