const addVisitorModel = {
    formId: 'createAccountForm',
    formField: {
        isPermanentVisitor: {
            name: 'isPermanentVisitor',
            label: 'Add as a Permanent Visitor',
        },
        visitorName: {
            name: 'visitorName',
            label: 'Visitor Name',
            required: true,
            requiredErrorMsg: 'Visitor name is required',
            invalidErrorMsg: 'Invalid name'
        },
        visitorEmail: {
            name: 'visitorEmail',
            label: 'Visitor Email',
            required: true,
            requiredErrorMsg: 'Visitor email address is required',
            invalidErrorMsg: 'Invalid email address'
        },
        visitorWhatsApp: {
            name: 'visitorWhatsApp',
            label: 'Visitor WhatsApp Number',
            required: false,
            invalidErrorMsg: 'Invalid WhatsApp number'
        },
        meetingWith: {
            name: 'meetingWith',
            label: 'Who is the meeting with?',
            required: true,
            requiredErrorMsg: 'Meeting With is required',
            invalidErrorMsg: 'Invalid Meeting With'
        },
        meetingDateTime: {
            name: 'meetingDateTime',
            label: 'Meeting Date & Time',
            required: true,
            requiredErrorMsg: 'Meeting Date & Time is required',
            invalidErrorMsg: 'Invalid Meeting Date & Time'
        },
        idNumber: {
            name: 'idNumber',
            label: 'ID Number',
            required: true,
            requiredErrorMsg: 'ID Number is required',
            invalidErrorMsg: 'Invalid ID Number'
        },
    },
    initialValues: {
        isPermanentVisitor: false,
        visitorName: '',
        visitorEmail: '',
        visitorWhatsApp: '',
        meetingWith: '',
        meetingDateTime: '',
        idNumber: '',
    }
};

export type addVisitorModelType = {
    isPermanentVisitor: boolean,
    visitorName: string,
    visitorEmail: string,
    visitorWhatsApp: string,
    meetingWith: string,
    meetingDateTime: string,
    idNumber: string,
}

export default addVisitorModel
  