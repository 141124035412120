import * as Yup from 'yup';
import logInModel from '../models/logInModel';

const {
  formField: {
    logInEmail,
    logInPass
  }
} = logInModel;

const logInSchema = {
    loggedIn: Yup.boolean(),
    [logInEmail.name]: Yup.string()
      .email(`${logInEmail.invalidErrorMsg}`)
      .when("loggedIn", {
        is: false,
        then: Yup.string()
          .required(`${logInEmail.requiredErrorMsg}`)
      }),
    [logInPass.name]: Yup.string()
      .when("loggedIn", {
        is: false,
        then: Yup.string()
          .required(`${logInPass.requiredErrorMsg}`)
          .min(8, `${logInPass.invalidErrorMsg}`)
      })
};

export default logInSchema
