import { Address, ContactInfo } from "../../../redux/vmsApi";


export const createStakeholderModel = {
    formId: 'stakeholderForm',
    formField: {
        stakeholderName: {
            name: 'stakeholderName',
            label: 'Stakeholder Name',
            requiredErrorMsg: 'Stakeholder name is required',
        },
        stakeholderType: {
            name: 'stakeholderType',
            label: 'Stakeholder Type',
            requiredErrorMsg: 'Stakeholder type is required',
        },
        tin: {
            name: 'tin',
            label: 'Tax Identification Number (TIN)',
        },
        paymentTerms: {
            name: 'paymentTerms',
            label: 'Payment Terms',
        },
        supplierCode: {
            name: 'supplierCode',
            label: 'Supplier Code',
        },
        customerCode: {
            name: 'customerCode',
            label: 'Customer Code',
        },
        gender: {
            name: 'customerCode',
            label: 'Customer Code',
        },
        productCategories: {
            name: 'productCategories',
            label: 'Product Categories',
        },
        contactInfo: {
            name: 'items',
            label: 'Requisition Items',
            required: true,
            requiredErrorMsg: 'At least one item is required',
            fields: {
                email: {
                    name: 'contactInfo.email',
                    label: 'Email',
                    requiredErrorMsg: 'Email is required',
                },
                phone: {
                    name: 'contactInfo.phone',
                    label: 'Phone',
                    requiredErrorMsg: 'Phone number is required',
                },
                contactPerson: {
                    name: 'contactInfo.contactPerson',
                    label: 'Contact Person',
                },
            }
        },
        address: {
            street: {
                name: 'address.street',
                label: 'Street',
                requiredErrorMsg: 'Street is required',
            },
            city: {
                name: 'address.city',
                label: 'City',
                requiredErrorMsg: 'City is required',
            },
            country: {
                name: 'address.country',
                label: 'Country',
            },
        },
        rating: {
            name: 'rating',
            label: 'Rating',
        },
        orderHistory: {
            name: 'orderHistory',
            label: 'Order History',
        },
        preferences: {
            name: 'preferences',
            label: 'Preferences',
        },
    },
    initialValues: {
        stakeholderName: '',
        stakeholderType: '',
        tin: '',
        paymentTerms: '',
        supplierCode: '',
        customerCode: '',
        productCategories: [],
        contactInfo: [  
            {
            email: '',
            phone:'',
            contactPerson: ''}],
        address: [{
            street: '',
            city: '',
            country: '',
        }],
        rating: undefined,
        orderHistory: [],
        preferences: {},
    },
};

export type createStakeholderModelType = {
    stakeholderName: string;
    stakeholderType: string;
    tin?: string;
    paymentTerms?: string;
    supplierCode?: string;
    customerCode?: string;
    gender?: string;
    productCategories?: string[];
    contactInfo: ContactInfo[];
    address: Address[];
    rating?: number;
    orderHistory?: object[];
    preferences?: object;
}



export default createStakeholderModel;
