import React, { useState, useEffect } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Stack,
  Select,
  MenuItem,
  IconButton,
  Chip,
  Typography,
  Card,
  Grid,
  CardContent,
  Divider,
  CardHeader,
  Box,
  Dialog,
  Badge,
  Tooltip,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Alert, AlertTitle } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


import { RequisitionItem, useGetUsersMeQuery, useGetProcurementProcessesByIdQuery, usePutProcurementProcessesByIdMutation, useGetDevelopmentsByIdQuery, useGetDevelopmentsProjectsQuery, Quotation } from '../../redux/vmsApi';
import PageWithHeader from '../common/PageWithHeader';
import { Formik, Form, FieldArray, Field } from 'formik';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from 'date-fns/locale';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import createRequisitionReviewModel, { RequisitionReviewType } from '../forms/models/createRequisitionReviewModel';
import LoadingCover from '../common/LoadingCover';
import ProcurementProcessDashboard from './ProcurementProcessDashboard';
import DropzoneArea from '../common/DropzoneArea';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

interface OwnProps {
  match?: any;
}
let updatedQuotationsAfterSelection: Quotation[];
const ViewRequisition: React.FC<OwnProps> = ({ match }) => {

  const { data: procurementProcess, isLoading, isError } = useGetProcurementProcessesByIdQuery({ id: match.params.uuid });
  const [updateProcurementProcess, { isSuccess, isLoading: isSubmitting }] = usePutProcurementProcessesByIdMutation();
  const { data: user } = useGetUsersMeQuery();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [showQuotationForm, setShowQuotationForm] = useState(false);

  const developmentId = procurementProcess?.requisition?.development.id;
  let procurementProcessPartial: any
  const { data: development } = useGetDevelopmentsByIdQuery(
    { id: developmentId! },
    { skip: !developmentId }
  );

  const { data: projects = [] } = useGetDevelopmentsProjectsQuery();
  const [openDialog, setOpenDialog] = useState<string | null>(null);
  const [selectedSupplier, setSelectedSupplier] = useState<number | null>(null);

  const handleDialogOpen = (url: string) => setOpenDialog(url);
  const handleDialogClose = () => setOpenDialog(null);
  useEffect(() => {
    setShowOverlay(isSubmitting || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false);
        setShowSuccessMsg(true);
        setRedirectToDashboard(true);
      }, 3000);
    }
  }, [isSubmitting, isSuccess]);





  const handleSelectSupplier = async (index: number, quotation: any) => {
    const newSelection = selectedSupplier === index ? null : index;
    setSelectedSupplier(newSelection);

    if (newSelection !== null && procurementProcess?.currentTask === "CPOPicksSupplier") {
      const updatedQuotations = procurementProcess?.pcReview?.quotations?.map((q) => {
        if (q.supplier === quotation.supplier) {
          return { ...q, selected: true };
        }
        return q;
      });

      if (updatedQuotations) {      
        updatedQuotationsAfterSelection = updatedQuotations;
      }



    }
  };


  const initialValues: RequisitionReviewType = {
    ...createRequisitionReviewModel.initialValues,
    quotations: [{ supplier: '', attachments: [], paymentTerms: '', remarks: '',selected:false }],
  };

  const handleSubmit = async (values: RequisitionReviewType) => {
    
    const reviewData: RequisitionReviewType = {
      comment: values.comment,
      approved: values.approved,
      user: user,
      quotations: values.quotations,
    };
   
    
    console.log("++++++qoutations+++++++++",values.quotations);
    procurementProcessPartial = {
      initiatedDate: procurementProcess?.initiatedDate,
      siteManagerReview: {
        comment: procurementProcess?.siteManagerReview?.comment,
        approved: procurementProcess?.siteManagerReview?.approved,
        user: {
          ...procurementProcess?.siteManagerReview?.user,
          previousToken: '',
        },
      },


      initiatedByUser: {
        ...procurementProcess?.initiatedByUser,
        previousToken: '',
      },
      requisition: {
        ...procurementProcess?.requisition,
        id: '',
        project: procurementProcess?.requisition.project,
        development: procurementProcess?.requisition.development,
        items: procurementProcess?.requisition?.items?.map((item: { quantity: any; unit: { id: any; name: any; }; }) => ({
          ...item,
          quantity: {
            ...item.quantity,
            id: undefined,
          },
          unit: {
            id: item.unit.id,
            name: item.unit.name,
          },
          id: undefined,
        })),
      },
    };

    if (procurementProcess?.cpoReview) {
      procurementProcessPartial["cpoReview"] = {
        comment: procurementProcess?.cpoReview?.comment,
        approved: procurementProcess?.cpoReview?.approved,
        user: {
          ...procurementProcess?.cpoReview?.user,
          previousToken: '',
        },
      };
    }

    if (procurementProcess?.pmReview) {
      procurementProcessPartial["pmReview"] = {
        comment: procurementProcess?.pmReview?.comment,
        approved: procurementProcess?.pmReview?.approved,
        user: {
          ...procurementProcess?.pmReview?.user,
          previousToken: '',
        },
      };
    }
    if (procurementProcess?.pcReview) {
      procurementProcessPartial["pcReview"] = {
        comment: procurementProcess?.pcReview?.comment,
        approved: procurementProcess?.pcReview?.approved,
        user: {
          ...procurementProcess?.pcReview?.user,
          previousToken: '',
        },

      };
    }

    if (procurementProcess?.pcReview) {
      procurementProcessPartial["pcReview"] = {
        comment: procurementProcess?.pcReview?.comment,
        approved: procurementProcess?.pcReview?.approved,
        user: {
          ...procurementProcess?.pcReview?.user,
          previousToken: '',
        },
        quotations: procurementProcess?.pcReview?.quotations?.map((quotation) => ({
          paymentTerms: quotation.paymentTerms,
          remarks: quotation.remarks,
          supplier: quotation.supplier,
          attachments: quotation.attachments?.map((attachment) => ({
            name: attachment.name,
            url: attachment.url,
          })),
        })),
      };

    }


    if (!values.approved) {
      procurementProcessPartial.currentTask = "CorrectPurchaseRequisition";
      procurementProcessPartial.currentTaskOwner = "site-agent";
      procurementProcessPartial.previousTask = "SiteManagerReviewPurchaseRequisition";
      if (procurementProcess?.currentTask === "SiteManagerReviewPurchaseRequisition") {
        procurementProcessPartial["siteManagerReview"] = reviewData;
      } else if (procurementProcess?.currentTask === "CPOReviewPurchaseRequisition") {
        procurementProcessPartial["cpoReview"] = reviewData;
        procurementProcessPartial.previousTask = "CPOReviewPurchaseRequisition";
      } else if (procurementProcess?.currentTask === "PCToUploadQuotations") {
        procurementProcessPartial["pcReview"] = reviewData;
        procurementProcessPartial.previousTask = "PCToUploadQuotations";
        procurementProcessPartial.currentTaskOwner = "procurement-clerk";
      }
      else if (procurementProcess?.currentTask === "PMToReviewProcurementApplication") {
        procurementProcessPartial["pmReview"] = reviewData;
        procurementProcessPartial.currentTask = "PCToUploadQuotations";
        procurementProcessPartial.currentTaskOwner = "procurement-clerk";
        procurementProcessPartial.previousTask = "PMToReviewProcurementApplication";
      }
      else if (procurementProcess?.currentTask === "CPOPicksSupplier") {
        procurementProcessPartial["cpoReview"] = reviewData;
        procurementProcessPartial.currentTask = "PMToReviewProcurementApplication";
        procurementProcessPartial.currentTaskOwner = "procurement-manager";
        procurementProcessPartial.previousTask = "CPOPicksSupplier";
      }
      else if (procurementProcess?.currentTask === "ProChairReviewsSupplierChoice") {
        procurementProcessPartial["proChairReview"] = reviewData;
        procurementProcessPartial.previousTask = "ProChairReviewsSupplierChoice";
        procurementProcessPartial.currentTask = "CPOPicksSupplier";
        procurementProcessPartial.currentTaskOwner = "cpo";
      }
      console.log("procurementProcessPartial", procurementProcessPartial)
    }

    if (values.approved && procurementProcess?.currentTask === "SiteManagerReviewPurchaseRequisition") {
      procurementProcessPartial["siteManagerReview"] = reviewData;
      procurementProcessPartial.currentTask = "CPOReviewPurchaseRequisition";
      procurementProcessPartial.currentTaskOwner = "cpo";
      procurementProcessPartial.previousTask = "SiteManagerReviewPurchaseRequisition";
    } else if (values.approved && procurementProcess?.currentTask === "CPOReviewPurchaseRequisition") {
      procurementProcessPartial.currentTask = "PCToUploadQuotations";
      procurementProcessPartial.currentTaskOwner = "procurement-clerk";
      procurementProcessPartial.previousTask = "CPOReviewPurchaseRequisition";
      procurementProcessPartial["cpoReview"] = reviewData;
      procurementProcessPartial.requisition.approved = true;


    }

    if (values.approved && procurementProcess?.currentTask === "PCToUploadQuotations") {
      procurementProcessPartial.currentTask = "PMToReviewProcurementApplication";
      procurementProcessPartial.currentTaskOwner = "procurement-manager";
      procurementProcessPartial.previousTask = "PCToUploadQuotations";
      procurementProcessPartial["pcReview"] = reviewData;


    }

    if (values.approved && procurementProcess?.currentTask === "PMToReviewProcurementApplication") {
      procurementProcessPartial.currentTask = "CPOPicksSupplier";
      procurementProcessPartial.currentTaskOwner = "cpo";
      procurementProcessPartial.previousTask = "PMToReviewProcurementApplication";
      procurementProcessPartial["pmReview"] = reviewData;

    }

    if (values.approved && procurementProcess?.currentTask === "CPOPicksSupplier") {

      procurementProcessPartial.currentTask = "ProChairReviewsSupplierChoice";
      procurementProcessPartial.currentTaskOwner = "pro-chair";
      procurementProcessPartial.previousTask = "CPOPicksSupplier";
      console.log("<<<<<<<<<<<updatedQuotationsAfterSelection>>>>>>>>>>", updatedQuotationsAfterSelection)
      if (updatedQuotationsAfterSelection) {
        procurementProcessPartial.pcReview.quotations = updatedQuotationsAfterSelection;
      }

      if (updatedQuotationsAfterSelection) {
        procurementProcessPartial.pcReview.quotations = updatedQuotationsAfterSelection.map(quotation => ({
          ...quotation,
          selected: !!quotation.selected 
        }));
      }
      procurementProcessPartial["cpoReview"] = reviewData;


    }

    if (values.approved && procurementProcess?.currentTask === "ProChairReviewsSupplierChoice") {
      procurementProcessPartial.currentTask = "PMToIssuePurchaseOrder";
      procurementProcessPartial.currentTaskOwner = "procurement-manager";
      procurementProcessPartial.previousTask = "CPOPicksSupplier"
      procurementProcessPartial["proChairReview"] = reviewData;

    }

    if (values.approved && procurementProcess?.currentTask === "PMToIssuePurchaseOrder") {
      procurementProcessPartial.currentTask = "ProcessReceiptOfGoods";
      procurementProcessPartial.currentTaskOwner = "procurement-clerk";
      procurementProcessPartial.previousTask = "PMToIssuePurchaseOrder";
      procurementProcessPartial["pmReview"] = reviewData;

    }







    console.log("Form data ", procurementProcessPartial)

    setShowOverlay(true);
    try {
      const result = await updateProcurementProcess({
        id: match.params.uuid,
        procurementProcessPartial
      }).unwrap();
      setShowOverlay(false);
      if (result) {
        console.log('success');
      }
    } catch (error) {
      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  };

  const getAlertMessage = () => {
    switch (procurementProcess?.currentTask) {
      case "SiteManagerReviewPurchaseRequisition":
        return "In this task as the Site Manager you are required to review and approve  or request  for requisition amendment  in the textbox below.";
      case "CPOReviewPurchaseRequisition":
        return "In this task as the Chief Procurement Officer you are required to review and approve  or request  for requisition amendment  in the textbox below.";
      case "PCToUploadQuotations":
        return "In this task as the Procurement Clerk either proceed and upload the supplier Quotations for review or reject and request a requisition amendment in the textbox below.";
      case "PMToReviewProcurementApplication":
        return "In this task you are required to review supplier information and quotations.You can do so by  clicking on the name of the supplier.";
      case "CPOPicksSupplier":
        return "In this task you are required to choose a supplier.Click on the name of the supplier to choose a supplier.";
      case "ProChairReviewsSupplierChoice":
        return "In this task as the ProChair you are required to review the chosen  supplier.Click on the name of the supplier to view  supplier details.";


      default:
        return "No action required";
    }
  };



  const renderDevelopmentDetails = () => (

    <Card
      sx={{
        margin: 2,
        padding: 2,
        backgroundColor: '#f5f5f5',
        borderRadius: 4,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #ccc',
      }}
    >

      <CardContent>





        <Typography variant="h4" gutterBottom color="primary" sx={{ fontWeight: 'bold' }}>
          Development Details
        </Typography>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={6}>
            <Typography variant="body1" color="textSecondary">
              <strong>Name:</strong> {development?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              <strong>Description:</strong> {development?.description}
            </Typography>
          </Grid>
        </Grid>
        <br></br>
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h5" gutterBottom color="secondary" sx={{ fontWeight: 'bold' }}>
          Project Details
        </Typography>
        <Table sx={{ backgroundColor: '#ffffff', borderRadius: 2, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <TableHead sx={{ backgroundColor: '#1976d2', color: 'white' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Start Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(() => {
              const matchingProject = development?.projects?.find(
                (p) => p.projectId === procurementProcess?.requisition?.project.id
              );

              if (matchingProject) {
                const renderStatusChip = (status: string) => {
                  switch (status) {
                    case 'Completed':
                      return <Chip label="Completed" color="success" sx={{ fontWeight: 'bold' }} />;
                    case 'Not Started':
                      return <Chip label="Not Started" color="default" sx={{ fontWeight: 'bold', backgroundColor: '#ffc107', color: 'black' }} />;
                    case 'In Progress':
                      return <Chip label="In Progress" color="warning" sx={{ fontWeight: 'bold' }} />;
                    default:
                      return <Chip label={status} sx={{ fontWeight: 'bold' }} />;
                  }
                };

                return (
                  <TableRow key={matchingProject.projectId}>
                    <TableCell>{matchingProject.name}</TableCell>
                    <TableCell>{matchingProject.description}</TableCell>
                    <TableCell>{renderStatusChip(matchingProject.status)}</TableCell>
                    <TableCell>{matchingProject.startDate}</TableCell>
                  </TableRow>
                );
              }

              return (
                <TableRow>
                  <TableCell colSpan={4}>No matching project found.</TableCell>
                </TableRow>
              );
            })()}
          </TableBody>
        </Table>



        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h5" gutterBottom color="secondary" sx={{ fontWeight: 'bold' }}>
          Requisition Item(s)  Details
        </Typography>
        <Table sx={{ backgroundColor: '#ffffff', borderRadius: 2, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <TableHead sx={{ backgroundColor: '#1976d2', color: 'white' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Description</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Quantity</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Stand</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Procured</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(procurementProcess?.requisition?.items || []).map(
              (item: RequisitionItem, index: number) => (
                procurementProcess?.requisition?.items ? (
                  <TableRow key={index}>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>
                      {item.quantity?.quantityValue} {item.quantity?.quantityUnit}
                    </TableCell>
                    <TableCell>{item.unit.name}</TableCell>
                    <TableCell>
                      {item.procured ? (
                        <Chip label="Yes" color="success" />
                      ) : (
                        <Chip label="No" color="error" />
                      )}
                    </TableCell>
                  </TableRow>
                ) : <TableRow>
                  <TableCell colSpan={4}>No matching project found.</TableCell>
                </TableRow>
              )
            )}







          </TableBody>
        </Table>

        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h5" gutterBottom color="secondary" sx={{ fontWeight: 'bold' }}>
          Requisition Reviewer's Comments
        </Typography>
        <Table sx={{ backgroundColor: '#ffffff', borderRadius: 2, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <TableHead sx={{ backgroundColor: '#1976d2', color: 'white' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Reviewer</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Comment</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Decision</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {procurementProcess?.siteManagerReview?.comment && (
              <TableRow >
                <TableCell>
                  {procurementProcess?.siteManagerReview?.user?.name ?? 'N/A'}
                  (
                  {procurementProcess?.siteManagerReview?.user?.roles?.join(', ') || 'No Roles'}
                  )
                </TableCell>

                <TableCell>
                  {procurementProcess?.siteManagerReview?.comment}
                </TableCell>

                <TableCell>
                  {procurementProcess.siteManagerReview.approved ? (
                    <Chip label="Approved" color="success" />
                  ) : (
                    <Chip label="Rejected" color="error" />
                  )}
                </TableCell>
              </TableRow>)}

            {procurementProcess?.cpoReview?.comment && (
              <TableRow>
                <TableCell>
                  {procurementProcess?.cpoReview?.user?.name ?? 'N/A'}
                  (
                  {procurementProcess?.cpoReview?.user?.roles?.join(', ') || 'No Roles'}
                  )
                </TableCell>


                <TableCell>{procurementProcess?.cpoReview?.comment}</TableCell>
                <TableCell>
                  <Chip
                    label={procurementProcess?.cpoReview?.approved ? 'Approved' : 'Rejected'}
                    color={procurementProcess?.cpoReview?.approved ? 'success' : 'error'}
                  />
                </TableCell>
              </TableRow>


            )}

            {procurementProcess?.pcReview?.comment && (
              <TableRow>
                <TableCell>
                  {procurementProcess?.pcReview?.user?.name ?? 'N/A'}
                  (
                  {procurementProcess?.pcReview?.user?.roles?.join(', ') || 'No Roles'}
                  )
                </TableCell>


                <TableCell>{procurementProcess?.pcReview?.comment}</TableCell>
                <TableCell>
                  <Chip
                    label={procurementProcess?.pcReview?.approved ? 'Approved' : 'Rejected'}
                    color={procurementProcess?.pcReview?.approved ? 'success' : 'error'}
                  />
                </TableCell>
              </TableRow>


            )}

            {procurementProcess?.pmReview?.comment && (
              <TableRow>
                <TableCell>
                  {procurementProcess?.pmReview?.user?.name ?? 'N/A'}
                  (
                  {procurementProcess?.pmReview?.user?.roles?.join(', ') || 'No Roles'}
                  )
                </TableCell>


                <TableCell>{procurementProcess?.pmReview?.comment}</TableCell>
                <TableCell>
                  <Chip
                    label={procurementProcess?.pmReview?.approved ? 'Approved' : 'Rejected'}
                    color={procurementProcess?.pmReview?.approved ? 'success' : 'error'}
                  />
                </TableCell>
              </TableRow>


            )}

            {procurementProcess?.proChairReview?.comment && (
              <TableRow>
                <TableCell>
                  {procurementProcess?.proChairReview?.user?.name ?? 'N/A'}
                  (
                  {procurementProcess?.proChairReview?.user?.roles?.join(', ') || 'No Roles'}
                  )
                </TableCell>


                <TableCell>{procurementProcess?.proChairReview?.comment}</TableCell>
                <TableCell>
                  <Chip
                    label={procurementProcess?.proChairReview?.approved ? 'Approved' : 'Rejected'}
                    color={procurementProcess?.proChairReview?.approved ? 'success' : 'error'}
                  />
                </TableCell>
              </TableRow>


            )}

            <TableRow>
              <TableCell colSpan={4}>No  comments from other reviewers yet.</TableCell>
            </TableRow>

          </TableBody>
        </Table>
        <br />
        <Alert
          severity="warning"
          icon={<WarningAmberIcon fontSize="large" />}
          sx={{
            backgroundColor: '#fff3e0',
            color: '#3A2D26',
            borderRadius: 2,
            padding: '16px',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <AlertTitle
            sx={{
              fontWeight: 'bold',
              fontSize: '1.2rem',
            }}
          >

            {getAlertMessage()}
          </AlertTitle>
        </Alert>


        {procurementProcess?.pcReview?.quotations && (
          <>
            <Divider sx={{ marginY: 2 }} />
            <Typography variant="h5" gutterBottom color="secondary" sx={{ fontWeight: 'bold' }}>
              {procurementProcess?.currentTask === "CPOPicksSupplier"
                ? "Select Supplier"
                : "Review Supplier Quotations"}
            </Typography>

            {procurementProcess.pcReview.quotations.map((quotation, index) => (
              <Accordion
                key={index}
                expanded={selectedSupplier === index}
                sx={{
                  backgroundColor: selectedSupplier === index ? '#e3f2fd' : '#f0f4fa',
                  borderRadius: 1,
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  marginBottom: 2,
                }}
              >
                <Tooltip
                  title={
                    procurementProcess?.currentTask === "PMToReviewProcurementApplication" ||
                      procurementProcess?.currentTask === "ProChairReviewsSupplierChoice"
                      ? "Click to view supplier details & Quotations"
                      : procurementProcess?.currentTask === "CPOPicksSupplier"
                        ? "Click to pick a supplier"
                        : "Click to select supplier"
                  }
                >
                  <AccordionSummary
                    onClick={() => {
                     
                      if (procurementProcess?.currentTask === "CPOPicksSupplier") {
                        handleSelectSupplier(index, quotation);
                      } else if (!quotation.selected) {
                       
                        handleSelectSupplier(index, quotation);
                      }
                    }}
                    expandIcon={<ExpandMoreIcon sx={{ color: '#ffffff' }} />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    sx={{
                      backgroundColor: '#1976d2',
                      color: 'white',
                      borderRadius: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'white' }}>
                      {quotation.supplier}
                    </Typography>

                    
                    {procurementProcess?.currentTask === "CPOPicksSupplier" ? (
                      selectedSupplier === index && (
                        <Badge color="secondary" variant="dot">
                          <CheckCircleIcon sx={{ color: '#F7C914', marginLeft: 2, fontSize: '2rem' }} />
                        </Badge>
                      )
                    ) : (
                      quotation.selected && (
                        <Badge color="secondary" variant="dot">
                          <CheckCircleIcon sx={{ color: '#F7C914', marginLeft: 2, fontSize: '2rem' }} />
                        </Badge>
                      )
                    )}
                  </AccordionSummary>
                </Tooltip>
                <AccordionDetails>
                  <Box sx={{ marginBottom: 2 }}>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}>
                      <strong>Payment Terms:</strong> {quotation.paymentTerms}
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: 1 }}>
                      <strong>Remarks:</strong> {quotation.remarks}
                    </Typography>
                  </Box>

                  <Table sx={{ width: '100%', backgroundColor: '#ffffff', borderRadius: 1 }}>
                    <TableBody>
                      {quotation?.attachments?.map((attachment, i) => (
                        <TableRow key={i} sx={{ '&:hover': { backgroundColor: '#e8f0fe' } }}>
                          <TableCell>{attachment.name}</TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleDialogOpen(attachment.url)} sx={{ color: '#1976d2' }}>
                              <VisibilityIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}




        <Dialog open={!!openDialog} onClose={handleDialogClose} fullWidth maxWidth="md">
          <Box p={2}>
            {openDialog && (
              <iframe
                src={openDialog}
                title="Document Viewer"
                width="100%"
                height="500px"
                style={{ border: 'none' }}
              />
            )}
            <Box textAlign="right" mt={2}>
              <Button onClick={handleDialogClose} variant="contained" color="primary">
                Close
              </Button>
            </Box>
          </Box>
        </Dialog>



        {procurementProcess?.currentTask === "PCToUploadQuotations" && (
          <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowQuotationForm(!showQuotationForm)}
            >
              {showQuotationForm ? "Request Requisition Amendments" : "Proceed with Quotations Upload"}
            </Button>
          </Stack>
        )}
      </CardContent>
    </Card>

  );







  if (redirectToDashboard) {
    return <ProcurementProcessDashboard />;
  }

  if (procurementProcess?.requisition && (!isLoading || !isError)) {
    return (
      <>

        <PageWithHeader>

          {/* {procurementProcess.requisition?.approved === false && (
            <Chip
              label={
                procurementProcess.cpoReview?.approved === true
                  ? 'REQUISITION APPROVED'
                  : 'REQUISITION NOT YET APPROVED'
              }
              color={
                procurementProcess.cpoReview?.approved === true
                  ? 'success'
                  : 'error'
              }
              sx={{
                fontWeight: 'bold',
                marginTop: '16px',
                marginBottom: '8px',
                width: '100%',
                textAlign: 'center',
              }}
            />
          )} */}

          {/* {procurementProcess.requisition?.approved === true && (
            <Chip
              label={
                procurementProcess.requisition?.approved === true
                  ? 'CREATED PURCHASE ORDER'
                  : 'PURCHASE ORDER NOT YET CREATED'
              }
              color={
                procurementProcess.requisition?.approved === true
                  ? 'success'
                  : 'error'
              }
              sx={{
                fontWeight: 'bold',
                marginTop: '16px',
                marginBottom: '8px',
                width: '100%',
                textAlign: 'center',
              }}
            />
          )} */}



          <Card
            sx={{
              background: 'linear-gradient(135deg, #1976d2 30%, #F7C914 90%)',
              color: 'white',
              padding: 3,
              borderRadius: 2,
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              position: 'relative',
              overflow: 'hidden',
              marginTop: 1,
              marginX: 2,
            }}
          >
            <CardContent sx={{ position: 'relative', zIndex: 2 }}>
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  letterSpacing: 1.5,
                  color: 'white'
                }}
              >
                {procurementProcess?.requisition.title}
              </Typography>
            </CardContent>
          </Card>
          {development && renderDevelopmentDetails()}

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                <Form>
                  <Stack spacing={4} width="100%" p={10} pb={5}>

                    {!showQuotationForm && (
                      <>
                        <TextField
                          name="comment"
                          label="Comments"
                          multiline
                          rows={4}
                          variant="outlined"
                          fullWidth
                          onChange={(e) => setFieldValue("comment", e.target.value)}
                        />
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                          <Stack direction="row" spacing={2}>

                            {procurementProcess?.currentTask !== "PCToUploadQuotations" && (
                              <Button
                                onClick={() => setFieldValue('approved', true)}
                                color="success"
                                variant="contained"
                                type="submit"
                              >
                                Approve
                              </Button>
                            )}
                            <Button
                              onClick={() => setFieldValue('approved', false)}
                              color="error"
                              variant="contained"
                              type="submit"
                            >
                              Reject
                            </Button>
                          </Stack>

                        </Stack>


                      </>
                    )}


                    {showQuotationForm && (
                      <>
                        <FieldArray name="quotations">
                          {({ push, remove }) => (
                            <Stack spacing={3}>
                              {values.quotations?.map((quotation, index) => (
                                <Card
                                  key={index}
                                  sx={{
                                    margin: 2,
                                    padding: 2,
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: 4,
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    border: '1px solid #ccc',
                                    width: '100%',
                                  }}
                                >
                                  <CardHeader
                                    title={
                                      <Typography variant="h6" component="div" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                                        Supplier Details
                                      </Typography>
                                    }
                                    action={
                                      <IconButton onClick={() => remove(index)} color="error">
                                        <RemoveCircleOutline />
                                      </IconButton>
                                    }
                                    sx={{ paddingBottom: 0 }}
                                  />
                                  <Divider sx={{ marginY: 2 }} />
                                  <CardContent>
                                    <Grid container spacing={2} alignItems="flex-start">

                                      <Grid item xs={12} md={3}>
                                        <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                                          Supplier
                                        </Typography>
                                        <Select
                                          name={`quotations.${index}.supplier`}
                                          fullWidth
                                          value={quotation.supplier}
                                          onChange={(e) => setFieldValue(`quotations.${index}.supplier`, e.target.value)}
                                          sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                        >
                                          <MenuItem value="Halsted">Halsted</MenuItem>
                                          <MenuItem value="Bhola">Bhola</MenuItem>
                                          <MenuItem value="Electric Sales">Electro Sales</MenuItem>
                                        </Select>
                                      </Grid>


                                      <Grid item xs={12} md={3}>
                                        <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                                          Payment Terms
                                        </Typography>
                                        <TextField
                                          name={`quotations.${index}.paymentTerms`}
                                          fullWidth
                                          variant="outlined"
                                          value={quotation.paymentTerms}
                                          onChange={(e) => setFieldValue(`quotations.${index}.paymentTerms`, e.target.value)}
                                          placeholder="Enter payment terms"
                                          sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                        />
                                      </Grid>


                                      <Grid item xs={12} md={3}>
                                        <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                                          Remarks
                                        </Typography>
                                        <TextField
                                          name={`quotations.${index}.remarks`}
                                          fullWidth
                                          variant="outlined"
                                          value={quotation.remarks}
                                          onChange={(e) => setFieldValue(`quotations.${index}.remarks`, e.target.value)}
                                          placeholder="Enter remarks"
                                          sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                        />
                                       
                                      </Grid>


                                      <Grid item xs={12} md={3}>
                                        <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                                          Attachments
                                        </Typography>
                                        <DropzoneArea
                                          setFieldValue={setFieldValue}
                                          fieldName={`quotations.${index}.attachments`}
                                          attachments={quotation.attachments || []}
                                        />
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              ))}
                              <Button
                                variant="outlined"
                                startIcon={<AddCircleOutline />}
                                onClick={() =>
                                  push({
                                    supplier: '',
                                    paymentTerms: '',
                                    remarks: '',
                                    attachments: [],
                                  })
                                }
                                sx={{
                                  color: '#1976d2',
                                  borderColor: '#1976d2',
                                  '&:hover': {
                                    backgroundColor: '#e3f2fd',
                                    borderColor: '#1976d2',
                                  },
                                }}
                              >
                                Add Quotation
                              </Button>
                            </Stack>
                          )}
                        </FieldArray>

                        <Button
                          onClick={() => setFieldValue('approved', true)}
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          Submit Documents
                        </Button>
                      </>
                    )}
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => setRedirectToDashboard(true)}
                    >
                      Go back Dashboard
                    </Button>
                  </Stack>

                </Form>
              </LocalizationProvider>
            )}
          </Formik>
          <div>
            {/* <Chip
              label={
                procurementProcess.cpoReview?.approved === true
                  ? 'REQUISITION APPROVED'
                  : 'REQUISITION NOT YET APPROVED'
              }
              color={
                procurementProcess.cpoReview?.approved === true
                  ? 'success'
                  : 'error'
              }
              style={{ fontWeight: 'bold' }}
            /> */}
          </div>

        </PageWithHeader>

        {showOverlay && <LoadingCover isSuccess={isSuccess} />}
        {showSuccessMsg && (
          <Stack>
            <Typography>Thank you, your response has been received</Typography>
            <Button onClick={() => window.location.reload()} variant="text" sx={{ maxWidth: '200px' }}>Create Another</Button>
          </Stack>
        )}
      </>
    );
  } else return <></>;

};

export default ViewRequisition;
