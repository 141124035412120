import React from 'react';
import {DialogTitle, DialogContent, Stack, Button, Typography, TextField, Link, IconButton} from "@mui/material";
import {
    Formik,
    Form,
    Field
} from 'formik';
import * as Yup from 'yup';
import {usePostPasswordResetRequestByUserEmailMutation} from '../../redux/vmsApi'
import LoadingCover from '../common/LoadingCover'
import {useAppDispatch} from "../../redux/hooks";
import authSlice from "../../redux/reducers/authSlice";
import { styles } from '../../theme/theme';
import { Close } from '@mui/icons-material';
import useIsMobile from '../../hooks/useIsMobile';

type MyFormValues = {
    email: string
}

const ResetRequestFormSchema = Yup.object().shape({
    email: Yup.string()
        .required('Please enter your account email address'),
})

interface OwnProps {
    close: () => void;
}

const ResetRequestForm = (props: OwnProps) => {
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();
    const [sendPasswordReset, {isLoading, isSuccess}] = usePostPasswordResetRequestByUserEmailMutation()
    const initialValues:MyFormValues = {
        email: ''
    };

    const handleSubmit = async (values: MyFormValues) => {
        const body = {userEmail: values.email}
        sendPasswordReset(body)
    }

    function openLogin() {
        dispatch(authSlice.actions.setAuthState('login'))
    }

    const successComponent = (
        <Stack spacing={4} alignItems="center" justifyContent="center" width="100%" height="100%" sx={{background: 'white', mx: 5}}>
            <Typography align={"center"} variant={'body1'}>
                Your request has been received.
            </Typography>
            <Typography align={"center"} variant={'body1'}>
               If an email address matching your account is found, you will receive an email with instructions on how to reset your password.
            </Typography>

            <Button variant="contained" onClick={openLogin} data-cy="backToLoginButton">
                Back to login
            </Button>
        </Stack>
    )

    return (
        <React.Fragment>
            <DialogTitle
                data-cy="dialogue-title" 
                sx={{
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'center',
                    justifyContent: isMobile ? 'space-between' : 'center'
                }}
            >
                <Typography variant="h2">
                    Reset Password
                </Typography>
                {
                    isMobile ?
                        <IconButton
                            onClick={props.close}
                        >
                            <Close
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: 26
                                }}
                            />
                        </IconButton> : null
                }
            </DialogTitle>

            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={ResetRequestFormSchema}
                >
                    {({errors}) => 
                        <Form>

                            <Stack spacing={8} pt={4} width="100%" alignItems="center">
                                <Field
                                as={TextField}
                                variant={styles.textFieldVariant}
                                fullWidth
                                data-cy={'reset-request-email'}
                                name={'email'}
                                label={'Email address'}
                                required={true}
                                inputProps={{required: false}}
                                type={'test'}
                                error={Boolean(errors.email)}
                                helperText={errors.email ? errors.email : ''}
                                />

                                <Button
                                    variant="contained"
                                    type="submit"
                                    data-cy={"resetRequestSubmit"}
                                >
                                    Reset Password
                                </Button>

                                <Typography align="center" variant="body1" noWrap >
                                    <Link onClick={openLogin}>Go back to login</Link>
                                </Typography>
                            </Stack>
                        </Form>
                    }
                </Formik>
            </DialogContent>
            {
                (isLoading || isSuccess) &&
                    <LoadingCover isSuccess={isSuccess} 
                        successComponent={successComponent}
                    />
            }
        </React.Fragment>
    )
}

export default ResetRequestForm;
