import React from 'react';
import PageWithHeader from "../common/PageWithHeader";
import { Grid, Card, CardContent, Typography, Box, Tooltip } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Link } from 'react-router-dom';
import {Assignment, LocalShipping, RateReview, Description, Home, Dashboard as DashboardIcon} from '@mui/icons-material';
import { useAppSelector } from "../../redux/hooks";
import LoggedOutView from "../common/LoggedOutView";

const useStyles = makeStyles(() => ({
  card: {
    height: '230px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1976d2',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
    transition: '0.3s',
    borderRadius: '15px',
    padding: '16px',
    '&:hover': {
      boxShadow: '0 8px 24px rgba(0, 0, 0, 0.5)',
      transform: 'scale(1.01)',
    },
  },
  cardDisabled: {
    height: '230px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#b0b0b0',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '15px',
    padding: '16px',
  },
  title: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '1.25rem',
  },
  titleDisabled: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '1.25rem',
  },
  link: {
    textDecoration: 'none', // Remove underline
    color: 'inherit', // Inherit text color
    display: 'block', // Make the link take the full height of the grid item
  },
  icon: {
    fontSize: '60px',
    marginBottom: '10px',
    color: '#fff',
  },
  iconDisabled: {
    fontSize: '60px',
    marginBottom: '10px',
    color: '#fff',
  },
}));

const Dashboard: React.FC = () => {
  const user = useAppSelector(state => state.auth.user)
  const classes = useStyles();
  const currentUser = useAppSelector(state => state.auth.user)

  const userAccess = {
    invite: !!(currentUser && currentUser.roles?.includes('vms')),
    procurement: !!(currentUser && currentUser.roles?.includes('procurement')),
    reviews: true,
    agreements: !!(currentUser && currentUser.roles?.includes('agreement')),
    propertyRegister: !!(currentUser && currentUser.roles?.includes('propertyRegister')),
    cSuite: !!(currentUser && currentUser.roles?.includes('cSuite')),
  };

  const options = [
    { id: 1, title: 'C Suite Dashboard', link: `/mid`, icon: <DashboardIcon className={classes.icon} />, access: userAccess.cSuite },
    { id: 2, title: 'Performance System', link: `/reviews`, icon: <RateReview className={classes.icon} />, access: userAccess.reviews },
    { id: 3, title: 'Procurement', link: `/procurement`, icon: <LocalShipping className={classes.icon} />, access: userAccess.procurement },
    { id: 4, title: 'Invite Management', link: `/invite`, icon: <Assignment className={classes.icon} />, access: userAccess.invite },
    { id: 5, title: 'Client Agreements', link: `/create-agreement`, icon: <Description className={classes.icon} />, access: userAccess.agreements },
    { id: 6, title: 'Property Register', link: `/property-register`, icon: <Home className={classes.icon} />, access: userAccess.propertyRegister },
  ];

  if (!user) {
    return <LoggedOutView />
  }

  return (
    <PageWithHeader>
      <Box m={5} display="flex" flexDirection="column" alignItems="center">
        <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456', fontSize: '2.5rem', textAlign: 'center' }}>
          WestProp Operations Hub
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {options.map((option) => (
            <Grid item xs={12} sm={6} md={4} key={option.id}>
              {option.access ? (
                <Link to={option.link} className={classes.link}>
                  <Card className={classes.card}>
                    <CardContent style={{ textAlign: 'center' }}>
                      {option.icon}
                      <Typography variant="h6" className={classes.title}>{option.title}</Typography>
                    </CardContent>
                  </Card>
                </Link>
              ) : (
                <Tooltip title="You do not have access to this function" arrow>
                  <Box>
                    <Card className={classes.cardDisabled}>
                      <CardContent style={{ textAlign: 'center' }}>
                        {React.cloneElement(option.icon, { className: classes.iconDisabled })}
                        <Typography variant="h6" className={classes.titleDisabled}>{option.title}</Typography>
                      </CardContent>
                    </Card>
                  </Box>
                </Tooltip>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </PageWithHeader>
  );
}

export default Dashboard;
