import React, {useEffect, useState} from 'react';
import {DialogTitle, DialogContent, Stack, Button, Typography, Link, IconButton} from "@mui/material";
import LoginFields from '../forms/LoginFields';
import {
    Formik,
    Form
} from 'formik';
import logInModel, {logInModelType} from '../forms/models/logInModel';
import logInSchema from '../forms/schemas/logInSchema';
import * as Yup from 'yup';
import {usePostUsersLoginMutation} from '../../redux/vmsApi'
import LoadingCover from '../common/LoadingCover'
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import authSlice from "../../redux/reducers/authSlice";
import { Close } from '@mui/icons-material';
import useIsMobile from '../../hooks/useIsMobile';

const { formField: {logInEmail} } = logInModel;

type MyFormValues = logInModelType

const logInModelInitial = logInModel.initialValues

const LogInFormSchema = Yup.object().shape({...logInSchema})
interface OwnProps {
    close: () => void;

}

const LoginForm = (props: OwnProps) => {
    const {close} = props
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();
    const [updateUserLogin, {isLoading, isSuccess, isError, error}] = usePostUsersLoginMutation()
    const [loginError, setLoginError] = useState('')
    const initialEmail = useAppSelector(state => state.auth.initialEmail)
    const initialValues:MyFormValues = {
        ...logInModelInitial,
        [logInEmail.name]: initialEmail
    };
    const authMessage = useAppSelector(state => state.auth.authMessage);

    const handleSubmit = async (values: MyFormValues) => {
        //here we will handle sending the form through to the api
        const body = {email: values.logInEmail, password: values.logInPass}
        updateUserLogin({body})
    }

    useEffect(() => {
        if(isSuccess) {
            setTimeout(() => {
                close()
            }, 300)
        }
    }, [isSuccess, close])

    useEffect(() => {
        if(isError && error) {
            const err:any = error
            if(err?.data?.error?.message) {
                setLoginError(err?.data?.error?.message)
            }
        }
        else {
            setLoginError('')
        }
    }, [isError, error])

    function openReset() {
        dispatch(authSlice.actions.setAuthState('reset'))
    }

    return (
        <React.Fragment>
            <DialogTitle
                data-cy="dialogue-title" 
                sx={{
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'center',
                    justifyContent: isMobile ? 'space-between' : 'center'
                }}
            >
                <Typography variant="h2">
                    Log In
                </Typography>
                {
                    isMobile ?
                        <IconButton
                            onClick={close}
                        >
                            <Close
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: 26
                                }}
                            />
                        </IconButton> : null
                }
            </DialogTitle>

            <DialogContent>
                <Typography align="center" variant="body1">
                    {authMessage}
                </Typography>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={LogInFormSchema}
                >
                    <Form>

                        <Stack spacing={8} width="100%" alignItems="center">
                            <LoginFields apiError={loginError}/>

                            <Button
                                    variant="contained"
                                    type="submit"
                                    data-cy={"loginSubmit"}
                            >
                                    Login
                            </Button>

                            <Stack spacing={2} width="100%" alignItems="center">
                                <Typography align="center" variant="body1" noWrap >
                                    <Link onClick={openReset}>forgot your password?</Link>
                                </Typography>
                            </Stack>
                        </Stack>
                    </Form>
                </Formik>
            </DialogContent>
            {
                (isLoading || isSuccess) &&
                    <LoadingCover isSuccess={isSuccess} />
            }
        </React.Fragment>
    )
}

export default LoginForm;
