import {PerformanceItem} from "../../../redux/vmsApi";

const employeeReviewModel = {
    formId: 'employeeReviewForm',
    formField: {
        reviewPeriod: {
            name: 'reviewPeriod',
            label: 'Review Period',
            required: true,
            requiredErrorMsg: 'Review period is required',
            invalidErrorMsg: 'Invalid entry'
        },
        accomplishments: {
            name: 'accomplishments',
            label: 'TOP 5 accomplishments duties & responsibilities (as per job description)',
            required: true,
            requiredErrorMsg: 'Accomplishments are required',
            invalidErrorMsg: 'Invalid entry'
        },
        quarterlyGoals: {
            name: 'quarterlyGoals',
            label: 'OKR\'s for Q1 2024',
            required: true,
            requiredErrorMsg: 'Quarterly goals are required',
            invalidErrorMsg: 'Invalid entry'
        },
        monthlyGoals: {
            name: 'monthlyGoals',
            label: 'Monthly Goals',
            required: true,
            requiredErrorMsg: 'Quarterly goals are required',
            invalidErrorMsg: 'Invalid entry'
        },
        areaToImprove: {
            name: 'areaToImprove',
            label: 'Areas to improve (personal and business)',
            required: true,
            requiredErrorMsg: 'Areas to improve is required',
            invalidErrorMsg: 'Invalid entry'
        },
        quarterlyObjective: {
            name: 'quarterlyObjective',
            label: 'Objective',
            required: true,
            requiredErrorMsg: 'Objective is required',
            invalidErrorMsg: 'Invalid entry'
        },
        examplesOfExcellence: {
            name: 'examplesOfExcellence',
            label: 'Give example of excellance / teamwork / core values / extra duties or work done',
            required: true,
            requiredErrorMsg: 'Examples are required',
            invalidErrorMsg: 'Invalid entry'
        },
        mainGoalToAchieve: {
            name: 'mainGoalToAchieve',
            label: 'My main goal to achieve next month is: (this must be part of either OKRs or monthly goals)',
            required: true,
            requiredErrorMsg: 'Examples are required',
            invalidErrorMsg: 'Invalid entry'
        },
        turnUpOnTime: {
            name: 'turnUpOnTime',
            label: 'Do you report to work on time?',
            required: true,
            requiredErrorMsg: 'This is required',
            invalidErrorMsg: 'Invalid Entry'
        },
        dailyCheckIn: {
            name: 'dailyCheckIn',
            label: 'Do you do your daily check in\'s?',
            required: true,
            requiredErrorMsg: 'This is required',
            invalidErrorMsg: 'Invalid Entry'
        },
        reportsOnTime: {
            name: 'reportsOnTime',
            label: 'Do you submit weekly and monthly reports on time?',
            required: true,
            requiredErrorMsg: 'This is required',
            invalidErrorMsg: 'Invalid Entry'
        },
        accomplishmentTitle: {
            name: 'accomplishmentTitle',
            label: 'Title',
        },
        accomplishmentRating: {
            name: 'accomplishmentRating',
            label: 'Self-Rating',
        },
        quarterlyTitle: {
            name: 'quarterlyTitle',
            label: 'Title',
        },
        quarterlyRating: {
            name: 'quarterlyRating',
            label: 'Self-Rating',
        },
        monthlyTitle: {
            name: 'monthlyTitle',
            label: 'Title',
        },
        monthlyRating: {
            name: 'monthlyRating',
            label: 'Self-Rating',
        }
    },
    initialValues: {
        reviewPeriod: '',
        accomplishments: [],
        quarterlyGoals: [],
        monthlyGoals: [],
        areaToImprove: '',
        examplesOfExcellence: '',
        mainGoalToAchieve: '',
        turnUpOnTime: false,
        dailyCheckIn: false,
        reportsOnTime: false,
        quarterlyObjective: '',
    }
};

export type employeeReviewType = {
    reviewPeriod: string;
    accomplishments: PerformanceItem[];
    quarterlyGoals: PerformanceItem[];
    monthlyGoals: PerformanceItem[];
    quarterlyObjective: string;
    areaToImprove: string;
    examplesOfExcellence: string;
    mainGoalToAchieve: string;
    turnUpOnTime: boolean;
    dailyCheckIn: boolean;
    reportsOnTime: boolean;
};

export default employeeReviewModel
