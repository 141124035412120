import React from 'react';
import {useAppSelector} from "../../redux/hooks";
import PageWithHeader from "../common/PageWithHeader";
import {Box, Card, CardContent, Grid, Typography} from "@mui/material";
import AddVisitor from "../common/AddVisitor";
import InviteDetails from "../common/InviteDetails";
import {InviteData} from "../../redux/vmsApi";
import LoggedOutView from "../common/LoggedOutView";
import UpcomingMeetings from "../common/UpcomingMeetings";

const VisitorManagementDashboard: React.FC = () => {
  const user = useAppSelector(state => state.auth.user)
  const [showCreatedInvite, setShowCreatedInvite] = React.useState(false)
  const [createdInvite, setCreatedInvite] = React.useState<InviteData | null>(null);

  const handleSuccess = (invite: InviteData) => {
    setCreatedInvite(invite);
    setShowCreatedInvite(true);
  };

  if (!user) {
    return <LoggedOutView />
  }

  if (!user.roles?.includes('vms')) {
    return (
      <PageWithHeader>
        <Box>
          <Typography>You don't have access to this functionality</Typography>
        </Box>
      </PageWithHeader>
    )
  }

  return (
    <PageWithHeader>
      <Grid container spacing={2} style={{padding: '20px'}}>
        <Grid item xs={12}>
          <Typography variant='h1' gutterBottom style={{fontWeight: 'bold', color: '#123456'}}>
            WestProp Operations Hub
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true} sx={{borderRadius: '20px', margin: 2, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}>
            <CardContent>
              <Typography variant='h3' gutterBottom style={{color: '#345678'}}>Add a Visitor</Typography>
              {!showCreatedInvite &&
                <AddVisitor onSuccess={handleSuccess} />
              }
              {showCreatedInvite && createdInvite &&
                <React.Fragment>
                  <InviteDetails
                    invite={createdInvite}
                    showCard={false}
                    showBanner={false}
                    showDownloadBtn={true}
                    showAddAnotherInviteBtn={true}
                    addAnotherInviteCallback={() => setShowCreatedInvite(false)}
                  />
                </React.Fragment>
              }
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card raised={true}
                sx={{borderRadius: '20px', margin: 2, padding: 2, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}>
            <CardContent>
              <Typography variant='h3' gutterBottom style={{color: '#56789A'}}>Upcoming Visitors</Typography>
              <UpcomingMeetings/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageWithHeader>
  )
}

export default VisitorManagementDashboard;