import * as Yup from 'yup';
import addVisitorModel from '../models/addVisitorModel';

const {
  formField: {
    isPermanentVisitor,
    visitorName,
    visitorEmail,
    visitorWhatsApp,
    meetingDateTime,
    meetingWith,
    idNumber,
  }
} = addVisitorModel;

const addVisitorSchema = {
    [isPermanentVisitor.name]: Yup.boolean(),
    [visitorName.name]: Yup.string()
        .required(`${visitorName.requiredErrorMsg}`),
    [visitorEmail.name]: Yup.string()
        .required(`${visitorEmail.requiredErrorMsg}`)
        .email(`${visitorEmail.invalidErrorMsg}`),
    [visitorWhatsApp.name]: Yup.string(),
  [meetingDateTime.name]: Yup.date()
    .when(isPermanentVisitor.name, {
      is: true,
      then: Yup.date().notRequired(),
      otherwise: Yup.date().required(meetingDateTime.requiredErrorMsg)
    }),
  [meetingWith.name]: Yup.string()
    .when(isPermanentVisitor.name, {
      is: true,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required(meetingWith.requiredErrorMsg)
    }),
  [idNumber.name]: Yup.string().required(idNumber.requiredErrorMsg),
  }
;

export default addVisitorSchema