import React from 'react';
import {Paper, Typography} from "@mui/material";
import CreateDevelopmentComponent from "./CreateDevelopmentComponent";
import ProcurementContainer from "./ProcurementContainer";
import DevelopmentsList from "./DevelopmentsList";

const DevelopmentsPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Developments
      </Typography>
      <Paper sx={{my: 5}}>
        <CreateDevelopmentComponent/>
      </Paper>
      <Paper sx={{my: 5}}>
        <DevelopmentsList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default DevelopmentsPage