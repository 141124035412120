import React, {useState} from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {Field, Form, Formik, useFormikContext} from 'formik';
import * as Yup from 'yup';
import LifeWheelVisualisation from './LifeWheelVisualisation';
import PageWithHeader from "../common/PageWithHeader";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {push} from "connected-react-router/immutable";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {LifeWheelData, usePostAddLifeWheelMutation} from "../../redux/vmsApi";
import {generatePastMonths} from "../../utils/periodUtil";

// Define the form values type
export interface LifeWheelValues {
  reviewPeriod: string;
  lifeWheel: {
    current: number[];
    desired: number[];
  };
}

// Define the initial values
const initialValues: LifeWheelValues = {
  reviewPeriod: '',
  lifeWheel: {
    current: Array(12).fill(0),
    desired: Array(12).fill(0),
  },
};

// Define the validation schema
const validationSchema = Yup.object({
  reviewPeriod: Yup.string().required('Review Period is required'),
  lifeWheel: Yup.object().shape({
    current: Yup.array().of(Yup.number().min(0).max(10).required()).required(),
    desired: Yup.array().of(Yup.number().min(0).max(10).required()).required(),
  }),
});

const sections = [
  {
    category: 'UPWARDS',
    color: 'Purple',
    items: [
      {id: 0, name: 'Church & God'},
      {id: 1, name: 'Marriage / Relationship'},
      {id: 2, name: 'Children'},
      {id: 3, name: 'Family'},
    ]
  },
  {
    category: 'ONWARDS',
    color: 'Blue',
    items: [
      {id: 4, name: 'Personal Finance'},
      {id: 5, name: 'Personal Growth'},
      {id: 6, name: 'Career Work'},
      {id: 7, name: 'Health / Exercise'},
      {id: 8, name: 'Reading / Hobbies'},
    ]
  },
  {
    category: 'OUTWARDS',
    color: 'Orange',
    items: [
      {id: 9, name: 'Friends'},
      {id: 10, name: 'Community'},
      {id: 11, name: 'Philanthropy'},
    ]
  }
];

const getColorForValue = (value: number): string => {
  if (value <= 3) return 'red';
  if (value <= 7) return 'orange';
  return 'green';
};

const LifeWheelForm: React.FC = () => {
  const {values, setFieldValue} = useFormikContext<LifeWheelValues>();

  const handleWheelChange = (index: number, field: 'current' | 'desired', value: number) => {
    const newValues = values.lifeWheel[field].slice();
    newValues[index] = value;
    setFieldValue(`lifeWheel.${field}`, newValues);
  };

  return (
    <Stack spacing={4} width="100%">
      {sections.map((section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          <Typography variant="h6" style={{color: section.color}}>{section.category}</Typography>
          <Grid container spacing={2}>
            {section.items.map((item) => (
              <React.Fragment key={item.id}>
                <Grid item xs={6}>
                  <TextField
                    label={`Current - ${item.name}`}
                    type="number"
                    inputProps={{min: 0, max: 10}}
                    fullWidth
                    value={values.lifeWheel.current[item.id]}
                    onChange={(e) => handleWheelChange(item.id, 'current', parseInt(e.target.value, 10))}
                    InputLabelProps={{
                      style: {color: getColorForValue(values.lifeWheel.current[item.id])}
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={`Desired - ${item.name}`}
                    type="number"
                    inputProps={{min: 0, max: 10}}
                    fullWidth
                    value={values.lifeWheel.desired[item.id]}
                    onChange={(e) => handleWheelChange(item.id, 'desired', parseInt(e.target.value, 10))}
                    InputLabelProps={{
                      style: {color: getColorForValue(values.lifeWheel.desired[item.id])}
                    }}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </React.Fragment>
      ))}
    </Stack>
  );
};

const LifeWheel: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.auth.user);

  const [postAddLifeWheelMutation] = usePostAddLifeWheelMutation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const pastMonths = generatePastMonths();

  const handleSubmit = async (values: LifeWheelValues, { resetForm }: { resetForm: () => void }) => {
    const dataToSubmit: LifeWheelData = {
      userId: currentUser?.id ?? '',
      reviewPeriod: values.reviewPeriod,
      responses: [{
        current: values.lifeWheel.current,
        desired: values.lifeWheel.desired,
      }],
    };
    try {
      const response = await postAddLifeWheelMutation({lifeWheelData: dataToSubmit}).unwrap();
      setIsSubmitted(true);
      resetForm();
    } catch (error) {
      console.error('There was an error submitting the form', error);
    }
  };

  return (
    <PageWithHeader>
      {!isSubmitted ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Stack spacing={4} width="100%" p={10} pb={5}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Button color="primary" onClick={() => dispatch(push('/reviews'))}>
                      <ArrowBackIcon/>
                      <Typography color="primary" align="left">Dashboard</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6} container justifyContent="flex-end">
                    <Button color="primary" onClick={() => dispatch(push('/reviews/life-wheel/historic'))}>
                      <Typography color="primary" align="right">View Historic Submissions</Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Typography variant='h1' gutterBottom style={{fontWeight: 'bold', color: '#123456'}}>
                  Life Wheel
                </Typography>
                <Typography variant="body1" gutterBottom>
                  The Life Wheel is designed to help you reflect on your current
                  state in various areas of your life and set goals for improvement. Please fill in the review period,
                  assess your
                  current state, and indicate your desired state in the Life Wheel sections.
                </Typography>
                <Typography variant="body1" gutterBottom>
                  The Life Wheel is divided into several sections representing different areas of your life. For each
                  section,
                  please rate your current and desired states on a scale of 0 to 10, where 0 is the lowest and 10 is the
                  highest.
                  The color of the labels will change based on your rating: Red for 0-3, Yellow for 4-7, and Green for
                  8-10.
                </Typography>

                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth required sx={{mb: 3}}>
                      <InputLabel>Review Period</InputLabel>
                      <Field
                        name="reviewPeriod"
                        as={Select}
                        label="Review Period"
                        onChange={(event: SelectChangeEvent) => {
                          setFieldValue('reviewPeriod', event.target.value);
                        }}
                      >
                        {pastMonths.map((quarter, index) => (
                          <MenuItem key={index} value={quarter}>{quarter}</MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                    <LifeWheelForm/>
                    <Button variant="contained" type="submit" sx={{mt: 4}}>
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div style={{position: 'sticky', top: '10px'}}>
                      <LifeWheelVisualisation data={values.lifeWheel}/>
                    </div>
                  </Grid>
                </Grid>
              </Stack>
            </Form>
          )}
        </Formik>
      ) : (
        <Stack spacing={4} width="100%" p={10} pb={5}>
          <Typography variant='h5' gutterBottom style={{color: '#123456'}}>
            Submission Successful!
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                disabled
              />
            }
            label="Thank you for your submission."
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(push('/reviews'))}
          >
            Back to Dashboard
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsSubmitted(false)}
          >
            Submit Another
          </Button>
        </Stack>
      )}
    </PageWithHeader>
  );
};

export default LifeWheel;
