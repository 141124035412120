import { Quotation, User } from "../../../redux/vmsApi";

const requisitionReviewFormModel = {
  formId: 'requisitionReviewForm',
  formField: {
    comment: {
      name: 'comment',
      label: 'Comment',
      required: true,
      requiredErrorMsg: 'Name is required',
    },
    approve: {
      name: 'approve',
      label: 'Approve',
      required: false,
    },
    
    quotations: {
      name: 'quotations',
      label: 'Quotations',
      required: true,
      requiredErrorMsg: 'At least one item is required',
      fields: {
        paymentTerms: {
          name: 'paymentTerms',
          label: 'Payment Terms',
          required: false,
        },
        remarks: {
          name: 'remarks',
          label: 'Remarks',
          required: false,
        },
        supplier: {
          name: 'supplier',
          label: 'Supplier',
          required: true,
          requiredErrorMsg: 'Supplier is required',
        },
        attachments: {
          name: 'attachments',
          label: 'Attachments',
          required: false,
        },
      },
    }
  },
  initialValues: {
    comment: '',
    user: {
      id: '',
      email: '',
      name: '',
      department: '',
      gender: '',
      roles: []
    },
    approved: false,
    
    quotations: [{
      paymentTerms:'',
      remarks:'',
      supplier: '',
      attachments: [],
      selected:true
    }]
  }
}

export type QuotationType = {
  supplier: string;
  attachments: { name: string; url: string; }[]
  paymentTerms: string;
  remarks:string;
 
};

export type RequisitionReviewType = {
  comment: string;
  approved: boolean;
  user?: User;
  quotations?: Quotation[];
};

export default requisitionReviewFormModel;
