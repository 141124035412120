import React, { ChangeEvent, useState } from 'react';
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik';
import createStakeholderModel from './models/createStakeholderModel';
import { DatePicker } from '@mui/x-date-pickers';
import { Delete as DeleteIcon, AddCircleOutline } from '@mui/icons-material';
import useCountries from '../../hooks/useCountry';

const {
    formField: {
        stakeholderName,
        stakeholderType,
        customerCode,
        supplierCode,
        paymentTerms,
        tin,
        contactInfo: {
            fields: itemFields
        },
        address,
        gender
    },
} = createStakeholderModel;

const CreateStakeholderForm = () => {
    const { errors, touched, values, setFieldValue } = useFormikContext<any>();
    const countries = useCountries();
    const handleDatePickerChange = (fieldName: string, value: unknown) => {
        setFieldValue(fieldName, value);
    };
    const developmentValues = [
        {
            label: "Supplier",
            value: "seatrite"
        },
        {
            label: "Customer",
            value: "customer"
        },
        {
            label: "Investor",
            value: "investor"
        },
    ];
    // const [stakeholderType, setStakeholderType] = useState('Supplier');
    
    const [gender, setGenderType] = useState('gender');
    return (
        <Stack width="100%" spacing={4} pt={4}>

            <FormControl variant="outlined">
                <InputLabel id="entity-type-select-label">Entity Type</InputLabel>
                {/* <Select
                    label={'Stakeholder Type'}
                    value={stakeholderType}
                    autoComplete="off"
                    onChange={(event: SelectChangeEvent) => {
                        setStakeholderType(event.target.value);
                    }}
                >
                    <MenuItem key={'Supplier'} value={'Supplier'}>
                        supplier
                    </MenuItem>
                    <MenuItem key={'Customer'} value={'Customer'}>
                        Customer
                    </MenuItem>
                </Select> */}

<Select
                    label="Stakeholder Type"
                    value={values.stakeholderType} 
                    autoComplete="off"
                    onChange={(event: SelectChangeEvent) => {
                        setFieldValue('stakeholderType', event.target.value); 
                    }}
                >
                    <MenuItem value="Supplier">Supplier</MenuItem>
                    <MenuItem value="Customer">Customer</MenuItem>
                    <MenuItem value="Investor">Investor</MenuItem>
                </Select>
            </FormControl>
            <Field
                as={TextField}
                fullWidth
                {...stakeholderName}
                label="Name"
                type="text"
                error={touched[stakeholderName.name] && Boolean(errors[stakeholderName.name])}
                helperText={touched[stakeholderName.name] && errors[stakeholderName.name] ? errors[stakeholderName.name] : ''}
            />


            {values.stakeholderType === 'Supplier' && (
                <Stack direction={'row'} spacing={4}>

                    <Field
                        as={TextField}
                        fullWidth
                        {...supplierCode}
                        type="text"
                        error={touched[supplierCode.name] && Boolean(errors[supplierCode.name])}
                        helperText={touched[supplierCode.name] && errors[supplierCode.name] ? errors[supplierCode.name] : ''}
                    />

                    <Field
                        as={TextField}
                        fullWidth
                        {...tin}
                        type="text"
                        error={touched[tin.name] && Boolean(errors[tin.name])}
                        helperText={touched[tin.name] && errors[tin.name] ? errors[tin.name] : ''}
                    />

                </Stack>

            )}

            {values.stakeholderType === 'Supplier' && (
                <Stack direction={'row'} spacing={4}>

                    <Field
                        as={TextField}
                        fullWidth
                        {...paymentTerms}
                        type="text"
                        error={touched[paymentTerms.name] && Boolean(errors[paymentTerms.name])}
                        helperText={touched[paymentTerms.name] && errors[paymentTerms.name] ? errors[paymentTerms.name] : ''}
                    />


                </Stack>

            )}

            {values.stakeholderType === 'Customer' && (
                <Stack direction={'row'} spacing={4}>

                    <Field
                        as={TextField}

                        {...customerCode}
                        type="text"
                        error={touched[customerCode.name] && Boolean(errors[customerCode.name])}
                        helperText={touched[customerCode.name] && errors[customerCode.name] ? errors[customerCode.name] : ''}
                    />

                    <ToggleButtonGroup
                        color="primary"
                        value={gender}
                        exclusive
                        onChange={(event, newGenderType) => {
                            if (newGenderType !== null) {
                                setGenderType(newGenderType);
                            }
                        }}
                        aria-label="Gender"
                    >
                        <ToggleButton value="Male">Male</ToggleButton>
                        <ToggleButton value="Female">Female</ToggleButton>
                    </ToggleButtonGroup>

                </Stack>

            )}



            <FieldArray name="contactInfo">
                {({ push, remove }) => (
                    <>
                        {values.contactInfo && values.contactInfo.length > 0 && values.contactInfo.map((item: any, index: number) => (
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                key={index}
                            >
                                <Field
                                    as={TextField}
                                    fullWidth
                                    name={`contactInfo[${index}].email`}
                                    label="Email"

                                    error={touched[`contactInfo[${index}].email.name`] && Boolean(errors[`contactInfo[${index}].email.name`])}
                                    helperText={touched[`contactInfo[${index}].email.name`] && errors[`contactInfo[${index}].email.name`] ? errors[`contactInfo[${index}].email.name`] : ''}

                                />
                                <Field
                                    as={TextField}
                                    fullWidth
                                    name={`contactInfo[${index}].phone`}
                                    label="Phone"

                                    error={touched[`contactInfo[${index}].phone.name`] && Boolean(errors[`contactInfo[${index}].phone`])}
                                    helperText={touched[`contactInfo[${index}].phone.name`] && errors[`contactInfo[${index}].phone.name`] ? errors[`contactInfo[${index}].phone.name`] : ''}
                                />
                                <Field
                                    as={TextField}
                                    fullWidth
                                    name={`contactInfo[${index}].contactPerson`}
                                    label="Contact Person"

                                    error={touched[`contactInfo[${index}].contactPerson.name`] && Boolean(errors[`contactInfo[${index}].contactPerson.name`])}
                                    helperText={touched[`contactInfo[${index}].contactPerson.name`] && errors[`contactInfo[${index}].contactPerson.name`] ? errors[`contactInfo[${index}].contactInfo.name`] : ''}
                                />
                                <IconButton onClick={() => remove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        ))}

                        <Button
                            onClick={() => push({ email: "", phone: "", contactPerson: "" })}
                            variant="outlined"
                            color="primary"
                            size="small"
                        >
                            Add Contact
                        </Button>
                    </>
                )}
            </FieldArray>

            <FieldArray name="address">
                {({ push, remove }) => (
                    <>
                        {values.address && values.address.length > 0 && values.address.map((item: any, index: number) => (
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                                key={index}
                            >
                                <Field
                                    as={TextField}
                                    fullWidth
                                    name={`address[${index}].street`}
                                    label="Street"

                                    error={touched[`address[${index}].street.name`] && Boolean(errors[`address[${index}].street.name`])}
                                    helperText={touched[`address[${index}].street.name`] && errors[`address[${index}].street.name`] ? errors[`address[${index}].street.name`] : ''}

                                />
                                <Field
                                    as={TextField}
                                    fullWidth
                                    name={`address[${index}].city`}
                                    label="city"

                                    error={touched[`address[${index}].city.name`] && Boolean(errors[`address[${index}].city`])}
                                    helperText={touched[`address[${index}].city.name`] && errors[`address[${index}].city.name`] ? errors[`address[${index}].city.name`] : ''}
                                />
                                

                                <Autocomplete
                                    options={countries}
                                    value={values.address?.country || ''}
                                    onChange={(event, value) => setFieldValue('address.country', value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            error={touched[`address[${index}].country.name`] && Boolean(errors[`address[${index}].country.name`])}
                                            helperText={touched[`address[${index}].country.name`] && errors[`address[${index}].country.name`] ? errors[`contactInfo[${index}].country.name`] : ''}
                                        />
                                    )}
                                />
                                <IconButton onClick={() => remove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                        ))}

                        <Button
                            onClick={() => push({ street: "", city: "", country: "" })}
                            variant="outlined"
                            color="primary"
                            size="small"
                        >
                            Add Address
                        </Button>
                    </>
                )}
            </FieldArray>

        </Stack>
    );
};

export default CreateStakeholderForm;
