export function formatAsLocalTime(meetingDateTime: string) {
  // Create a new Date object
  let localDate = new Date(meetingDateTime);

  // Get the time zone offset in minutes and convert it to milliseconds
  let timezoneOffset = localDate.getTimezoneOffset() * 60000;

  // Adjust the date by the time zone offset
  localDate = new Date(localDate.getTime() - timezoneOffset);

  // Format the date
  return localDate.toLocaleString('en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });
}

export function getDateFromObjectId(objectId: string) {
  // ObjectID's timestamp is the first 4 bytes
  // Convert the objectId to a hex string and then get the substring containing the timestamp
  const timestamp = objectId.toString().substring(0, 8);

  // Convert the timestamp from hex to an integer
  const timestampInt = parseInt(timestamp, 16);

  // Create a new Date object based on the timestamp in milliseconds
  return new Date(timestampInt * 1000).toLocaleString();
}