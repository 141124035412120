const logInModel = {
    formId: 'logInForm',
    formField: {
      logInEmail: {
        name: 'logInEmail',
        label: 'Email address',
        required: true,
        inputType: 'email',
        requiredErrorMsg: 'Email address is required',
        invalidErrorMsg: 'Invalid email address',
      },
      logInPass: {
        name: 'logInPass',
        label: 'Password',
        required: true,
        inputType: 'password',
        requiredErrorMsg: 'Password is required',
        invalidErrorMsg: 'Password must contain at least 8 characters',
      }
    },
    initialValues: {
      logInEmail: '',
      logInPass: '',
      loggedIn: false
    }
};

export type logInModelType = {
  logInEmail: string,
  logInPass: string,
  loggedIn: boolean
}

export default logInModel
