import React from 'react';
import {
  Unit, useGetDevelopmentsProjectsQuery, useGetDevelopmentsQuery,
  useGetUnitsQuery,
} from "../../redux/vmsApi";
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

const UnitsList: React.FC = () => {
  const { data: units, isLoading, isError } = useGetUnitsQuery({});
  const { data: projects, isLoading: projectsIsLoading, isError: projectsIsError } = useGetDevelopmentsProjectsQuery();
  const { data: developments, isLoading: developmentsIsLoading, isError: developmentsIsError } = useGetDevelopmentsQuery();

  if (isLoading || projectsIsLoading || developmentsIsLoading) {
    return <CircularProgress />;
  }

  if (isError || projectsIsError || developmentsIsError) {
    return <div>Error fetching data.</div>;
  }

  const findDevelopmentForProject = (projectId: string) => {
    if (developments) {
      for (const development of developments) {
        if (development.projects.some(project => project.projectId === projectId)) {
          return development.name; // or development.name or any other relevant property
        }
      }
    }
    return 'Development not found';
  };

  return (
    <Box p={10}>
      <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        View Units
      </Typography>
      <TableContainer>
        <Table sx={{tableLayout: 'fixed'}}>
          <TableHead>
            <TableRow>
              <TableCell>Unit Name</TableCell>
              <TableCell>Development</TableCell>
              <TableCell>Project</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {units?.map((unit: Unit) => (
              <TableRow key={unit.id}>
                <TableCell>{unit.unitName}</TableCell>
                <TableCell>
                  {projects &&
                    (() => {
                      const [project] = projects.filter(project => project?.projectId === unit.projectId);
                      return project ? findDevelopmentForProject(project.projectId ?? '') : 'Development not found';
                    })()
                  }
                </TableCell>
                <TableCell>
                  {projects &&
                    (() => {
                      const [project] = projects.filter(project => project?.projectId === unit.projectId);
                      return project ? project.name : 'Project not found';
                    })()
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default UnitsList;
