import React from 'react';
import AddClientForm from "./AddClientForm";
import {Box, CircularProgress, Paper, Typography} from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import PageWithHeader from "../common/PageWithHeader";
import {useGetClientsQuery} from "../../redux/vmsApi";

const Clients: React.FC = () => {
  const {data: clients, isLoading} = useGetClientsQuery({});

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'clientName', headerName: 'Client Name', width: 150 },
    { field: 'clientIdNumber', headerName: 'Client ID Number', width: 150 },
    { field: 'clientEmail', headerName: 'Client Email', width: 250 },
  ];

  return (
    <PageWithHeader padding={5}>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Client Management
      </Typography>
      <Paper sx={{my: 5}}>
        <AddClientForm />
      </Paper>
      <Paper sx={{my: 5, p: 5}}>
        <Typography variant='h2' gutterBottom>
          Existing Clients
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height={400}>
            <CircularProgress />
          </Box>
        ) : (
            <DataGrid
              rows={clients || []}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[10, 10]}
              checkboxSelection
            />
        )}
      </Paper>
    </PageWithHeader>
  )
}

export default Clients;