import React, {useEffect} from 'react';
import {Stack, TextField, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, FormHelperText} from '@mui/material';
import createAccountModel from './models/createAccountModel'
import {Field, useFormikContext} from "formik";

const { formField: {createAccountName, createAccountDepartment, createAccountEmail, createAccountPass, createAccountGender} } = createAccountModel;

interface OwnProps {
  apiError?: string
}

const CreateAccountForm = (props: OwnProps) => {
  const {errors, touched, setErrors} = useFormikContext<any>();
  useEffect(() => {
    setErrors({[createAccountPass.name]: props.apiError})
  }, [props.apiError, setErrors])

    return (
        <Stack width="100%" spacing={4} pt={4}>
            <Field
                as={TextField}
                fullWidth
                name={createAccountName.name}
                label={createAccountName.label}
                required={createAccountName.required}
                inputProps={{required: false}}
                type="text"
                autoComplete="off"
                error={touched[createAccountName.name] && Boolean(errors[createAccountName.name])}
                helperText={(touched[createAccountName.name] && errors[createAccountName.name]) ? errors[createAccountName.name] : ''}
            />

            <Field
                as={TextField}
                fullWidth
                name={createAccountDepartment.name}
                label={createAccountDepartment.label}
                required={createAccountDepartment.required}
                inputProps={{required: false}}
                type="text"
                autoComplete="off"
                error={touched[createAccountDepartment.name] && Boolean(errors[createAccountDepartment.name])}
                helperText={(touched[createAccountDepartment.name] && errors[createAccountDepartment.name]) ? errors[createAccountDepartment.name] : ''}
            />

            <Field
                as={TextField}
                fullWidth
                name={createAccountEmail.name}
                label={createAccountEmail.label}
                required={createAccountEmail.required}
                inputProps={{required: false}}
                type="email"
                autoComplete="email"
                error={touched[createAccountEmail.name] && Boolean(errors[createAccountEmail.name])}
                helperText={(touched[createAccountEmail.name] && errors[createAccountEmail.name]) ? errors[createAccountEmail.name] : ''}
            />

            <Field
                as={TextField}
                fullWidth
                name={createAccountPass.name}
                label={createAccountPass.label}
                required={createAccountPass.required}
                inputProps={{required: false}}
                type="password"
                autoComplete="password"
                error={touched[createAccountPass.name] && Boolean(errors[createAccountPass.name])}
                helperText={(touched[createAccountPass.name] && errors[createAccountPass.name]) ? errors[createAccountPass.name] : ''}
            />

            <FormControl component="fieldset" error={touched[createAccountGender.name] && Boolean(errors[createAccountGender.name])}>
                <FormLabel component="legend">{createAccountGender.label}</FormLabel>
                <Field name={createAccountGender.name}>
                    {({ field }: any) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                        </RadioGroup>
                    )}
                </Field>
                {touched[createAccountGender.name] && errors[createAccountGender.name] && (
                    <FormHelperText>{errors[createAccountGender.name]}</FormHelperText>
                )}
            </FormControl>
        </Stack>
    )
}

export default CreateAccountForm
