import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FieldProps } from "formik";
import { DateTimePicker } from "@mui/x-date-pickers";
import { enGB } from 'date-fns/locale'
import './DateRangePicker.css'
import { TextField, TextFieldProps } from '@mui/material';

interface OwnProps extends FieldProps {
  minDate?: Date;
  onChange?: (date: Date | null) => void;
}

const DateTimeField: React.FC<OwnProps> = (props) => {
  const { field, form, minDate, onChange } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <DateTimePicker
        className="fullWidth"
        value={field.value ? new Date(field.value) : null}
        minDate={minDate}
        onChange={(newValue: Date | null) => {
          if (newValue && !isNaN(newValue.getTime())) {
            form.setFieldValue(field.name, newValue);
            if (onChange) onChange(newValue);
          } else {
            form.setFieldValue(field.name, null);
            if (onChange) onChange(null);
          }
        }}
        renderInput={(params: TextFieldProps) =>
          <TextField
            fullWidth
            inputProps={{ required: false }}
            {...params}
            data-cy={`${field.name}-start`}
            error={Boolean(field.name in form.errors && field.name in form.touched)}
            helperText={Boolean(field.name in form.errors && field.name in form.touched) ? form.errors[field.name] : ''}
          />
        }
      />
    </LocalizationProvider>
  );
}

export default DateTimeField;
