import React, {useEffect, useState} from 'react';
import {Button, Stack, Typography} from "@mui/material";
import {Form, Formik} from 'formik';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {enGB} from 'date-fns/locale';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import billOfMaterialFormModel, {createBillOfMaterialModelType} from '../forms/models/createBillOfMaterialModel';
import CreateBillOfMaterialsForm from './CreateBomForm';
import {usePostBomMutation} from '../../redux/vmsApi';
import LoadingCover from "../common/LoadingCover";

type MyFormValues = createBillOfMaterialModelType;

const createBillOfMaterialModelInitial = billOfMaterialFormModel.initialValues;

type Props = {
  onSuccess?: (bomData: createBillOfMaterialModelType) => void;
};
const CreateBillOfMaterialComponent: React.FC<Props> = ({onSuccess}) => {
  const [createBom, {isSuccess, isLoading}] = usePostBomMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false);
        setShowSuccessMsg(true);
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: MyFormValues = {
    ...createBillOfMaterialModelInitial,
  };

  const handleSubmit = async (values: MyFormValues) => {
    setShowOverlay(true);
    try {
      const result = await createBom({bomData: values}).unwrap();
      setShowOverlay(false);
      if (result) {
        console.log('success');
      }
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  };

  const resetForm = () => {
    window.location.reload()
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess}/>}
      {!showSuccessMsg ?
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
            <Form>
              <Stack spacing={4} width="100%" p={10} pb={5}>
                <CreateBillOfMaterialsForm/>
                <Button
                  variant="contained"
                  type="submit"
                >
                  Create
                </Button>
              </Stack>
            </Form>
          </LocalizationProvider>
        </Formik> :
        <Stack>
          <Typography>Your Bill of Material has successfully been created</Typography>
          <Button onClick={() => resetForm()} variant={'text'} sx={{maxWidth: '200px'}}>Create Another</Button>
        </Stack>
      }
    </React.Fragment>
  );
};

export default CreateBillOfMaterialComponent;
