import React, {useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography,} from '@mui/material';
import PageWithHeader from "../common/PageWithHeader";
import {useAppSelector} from "../../redux/hooks";
import {EmployeeReview, useGetEmployeeReviewsByUserByUserIdQuery} from "../../redux/vmsApi";
import SPEARTable from "./SPEARTable";
import LoadingCover from "../common/LoadingCover";

const HistoricSPEAR: React.FC = () => {
  const currentUser = useAppSelector(state => state.auth.user);
  const {data: historicSPEARS, isLoading, error} = useGetEmployeeReviewsByUserByUserIdQuery({userId: currentUser?.id ?? ''});
  const [selectedMonth, setSelectedMonth] = useState<string>('');

  if (isLoading) return <LoadingCover/>;
  if (error) return <div>Error loading data</div>;

  const handleMonthChange = (event: SelectChangeEvent<string>) => {
    setSelectedMonth(event.target.value);
  };

  const selectedSPEAR = historicSPEARS?.find(lw => lw.reviewPeriod === selectedMonth);

  return (
    <PageWithHeader>
      <Stack spacing={4} width="100%" p={10} pb={5}>
        <Typography variant='h1' gutterBottom style={{fontWeight: 'bold', color: '#123456'}}>
          Your Historic SPEAR Submissions
        </Typography>
        <FormControl variant="outlined" fullWidth required>
          <InputLabel>Review Period</InputLabel>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            label="Review Period"
          >
            {historicSPEARS?.map((SPEAR: EmployeeReview) => (
              <MenuItem key={SPEAR.reviewPeriod} value={SPEAR.reviewPeriod}>
                {SPEAR.reviewPeriod}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedSPEAR && (
          <Box>
            <Typography variant="h5" gutterBottom>
              SPEAR for {selectedMonth}
            </Typography>
            <SPEARTable reviewData={selectedSPEAR} userData={currentUser ?? null}/>
          </Box>
        )}
      </Stack>
    </PageWithHeader>
  );
}

export default HistoricSPEAR;
