const requisitionFormModel = {
    formId: 'requisitionForm',
    formField: {
        title: {
            name: 'title',
            label: 'Title',
            required: true,
            requiredErrorMsg: 'Title is required',
        },
        development: {
            name: 'Development',
            label: 'Development',
            required: true,
            requiredErrorMsg: 'Title is required',
        },
        developmentName: {
            name: 'DevelopmentName',
            label: 'DevelopmentName',
            required: true,
            requiredErrorMsg: 'Title is required',
        },
        project: {
            name: 'Project',
            label: 'Project',
            required: true,
            requiredErrorMsg: 'Title is required',
        },
        projectName: {
            name: 'project Name',
            label: 'project Name',
            required: true,
            requiredErrorMsg: 'Title is required',
        },
              
        items: {
            name: 'items',
            label: 'Requisition Items',
            required: true,
            requiredErrorMsg: 'At least one item is required',
            fields: {
                 unit: {
                   name: 'unit',
                   label: 'Housing Unit',
                   required: true,
                   requiredErrorMsg: 'Housing is required',
                 },
                 unitName: {
                    name: 'unit',
                    label: 'Housing Unit',
                    required: true,
                    requiredErrorMsg: 'Housing is required',
                  },
                 bomItem: {
                    name: 'bomItem',
                    label: 'Bom Item',
                    required: true,
                    requiredErrorMsg: 'Unit is required',
                 },
                 description: {
                     name: 'description',
                     label: 'Description',
                     required: true,
                     requiredErrorMsg: 'Description is required',
                 },
                 quantity: {
                    name: 'quantity',
                    label: 'Quantity',
                    required: true,
                    requiredErrorMsg: 'Quantity is required',
                    quantityValue: {
                        name: 'quantityValue',
                        label: 'Quantity',
                        required: true,
                        requiredErrorMsg: 'Quantity is required',
                    },
                    quantityUnit: {
                        name: 'quantityUnit',
                        label: 'Unit',
                           required: true,
                           requiredErrorMsg: 'Unit is required',
                        }
                    },
                    
        
        },
        
            
        },
    },
    initialValues: {
        title: '',
        development: '',
        project:'',
        developmentName:'',
        projectName:'',
        items: [{
            unit: '',
            unitName:'',
            bomItem:'',
            description: '',
            quantity: {
                quantityValue: 0,
                quantityUnit: ''
            },                   
        }],
    },
};

export type QuantityType = {
    quantityValue: number;
    quantityUnit: string;
    
};

export type ItemType = {
    quantity: QuantityType;
    description: string;
    unit:string;
    unitName:string;
};



export type createRequisitionModelType = {
    items: ItemType[];
    development: string;
    project: string;
    title: string;
    developmentName:string;
    projectName:string;
};

export default requisitionFormModel;
