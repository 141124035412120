import React, {useState} from 'react';
import PageWithHeader from "../common/PageWithHeader";
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import QRCodeScanner from "../common/QRCodeScanner";
import {useGetInviteByIdQuery, usePatchInviteMarkAsScannedByIdMutation} from "../../redux/vmsApi";
import InviteDetails from "../common/InviteDetails";
import InviteDetailsContainer from "../common/InviteDetailsContainer";
import {useAppSelector} from "../../redux/hooks";

const Scan: React.FC = () => {
  const [isScanning, setIsScanning] = useState(true); // State to control QR scanner
  const [inviteId, setInviteId] = useState('');
  const [patchInviteMarkAsScanned] = usePatchInviteMarkAsScannedByIdMutation();
  const { data: invite, isLoading, isError, refetch } = useGetInviteByIdQuery({id: inviteId}, {
    skip: !inviteId, // you can set this to true initially and set to false when you have an ID
  });
  const user = useAppSelector(state => state.auth.user)

  const handleQRCodeScan = async (id: string) => {
    setInviteId(id);
    setIsScanning(false);
    refetch();

    await patchInviteMarkAsScanned({ id });
  };

  const handleScanAgain = () => {
    setIsScanning(true);
    setInviteId('');
  };

  if (!user?.roles?.includes('vms')) {
    return (
      <PageWithHeader>
        <Box>
          <Typography>You don't have access to this functionality</Typography>
        </Box>
      </PageWithHeader>
    )
  }

  return (
    <PageWithHeader>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} md={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Typography variant="h3" gutterBottom>Scan</Typography>
          {isScanning ? (
            <QRCodeScanner onScan={handleQRCodeScan} />
          ) : (
            <Button variant="contained" onClick={handleScanAgain} style={{ marginTop: '20px' }}>
              Scan Another
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : isError ? (
            <Typography>Error loading invite.</Typography>
          ) : (
            !!inviteId ? (
              <InviteDetailsContainer>
                <InviteDetails invite={invite} captureId={false} />
              </InviteDetailsContainer>
            ) : (
              <Paper elevation={3} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 200 }}>
                <Typography variant="h5">Scan a QR Code to view invite details</Typography>
              </Paper>
            )
          )}
        </Grid>
      </Grid>
    </PageWithHeader>
  );
}

export default Scan;
