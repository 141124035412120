import React from 'react';
import {Paper, Typography} from "@mui/material";
import ProcurementContainer from "./ProcurementContainer";
import CreateUnitComponent from "./CreateUnitComponent";
import UnitsList from "./UnitsList";

const UnitsPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Units
      </Typography>
      <Paper sx={{my: 5}}>
        <CreateUnitComponent/>
      </Paper>
      <Paper sx={{my: 5}}>
        <UnitsList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default UnitsPage;