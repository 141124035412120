import React, {ChangeEvent} from 'react';
import {Checkbox, FormControlLabel, Stack, TextField} from '@mui/material';
import addVisitorModel from './models/addVisitorModel'
import {Field, useFormikContext} from "formik";
import { styles } from '../../theme/theme';
import DateTimeField from "../ui/formikControls/DateTimeField";

const { formField: {isPermanentVisitor, visitorName, visitorEmail, visitorWhatsApp, idNumber, meetingWith, meetingDateTime} } = addVisitorModel;


const AddVisitorForm = () => {
  const { errors, touched, values, setFieldValue } = useFormikContext<any>();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(isPermanentVisitor.name, event.target.checked);
  };

  return (
    <Stack width="100%" spacing={4}>
      <FormControlLabel
        control={<Checkbox
          checked={values[isPermanentVisitor.name]}
          onChange={handleCheckboxChange}
        />
      }
        {...isPermanentVisitor}
      />
      <Field
        as={TextField}
        variant={styles.textFieldVariant}
        fullWidth
        {...visitorName}
        type="text"
        autoComplete="off"
        error={touched[visitorName.name] && Boolean(errors[visitorName.name])}
        helperText={(touched[visitorName.name] && errors[visitorName.name]) ? errors[visitorName.name] : ''}
      />

      <Field
        as={TextField}
        variant={styles.textFieldVariant}
        fullWidth
        {...visitorEmail}
        type="email"
        autoComplete="off"
        error={touched[visitorEmail.name] && Boolean(errors[visitorEmail.name])}
        helperText={(touched[visitorEmail.name] && errors[visitorEmail.name]) ? errors[visitorEmail.name] : ''}
      />

      <Field
        as={TextField}
        variant={styles.textFieldVariant}
        fullWidth
        {...visitorWhatsApp}
        autoComplete="off"
        error={touched[visitorWhatsApp.name] && Boolean(errors[visitorWhatsApp.name])}
        helperText={(touched[visitorWhatsApp.name] && errors[visitorWhatsApp.name]) ? errors[visitorWhatsApp.name] : ''}
      />
      <Field
        as={TextField}
        variant={styles.textFieldVariant}
        fullWidth
        {...idNumber}
        type="text"
        autoComplete="off"
        error={touched[idNumber.name] && Boolean(errors[idNumber.name])}
        helperText={(touched[idNumber.name] && errors[idNumber.name]) ? errors[idNumber.name] : ''}
      />
      {!values[isPermanentVisitor.name] &&
        <React.Fragment>
          <Field
            as={TextField}
            variant={styles.textFieldVariant}
            {...meetingWith}
            fullWidth
            type="text"
            autoComplete="off"
            error={touched[meetingWith.name] && Boolean(errors[meetingWith.name])}
            helperText={(touched[meetingWith.name] && errors[meetingWith.name]) ? errors[meetingWith.name] : ''}
          />
          <Field
            component={DateTimeField}
            variant={styles.textFieldVariant}
            {...meetingDateTime}
            fullWidth
            type="text"
            autoComplete="off"
            error={touched[meetingDateTime.name] && Boolean(errors[meetingDateTime.name])}
            helperText={(touched[meetingDateTime.name] && errors[meetingDateTime.name]) ? errors[meetingWith.name] : ''}
          />
        </React.Fragment>
      }
    </Stack>
  )
}

export default AddVisitorForm;
