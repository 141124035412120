import { createTheme } from "@mui/material/styles";


export const theme = createTheme({
  spacing: 4,
  typography: {
    allVariants: {
      color: '#555',
    },
    h1: {
      fontWeight: 600, // Adjusted for more emphasis
      fontSize: '2rem',
    },
    h2: {
      fontWeight: 'normal',
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '1.4rem'
    },
    h4: {
      fontSize: '1.3rem'
    },
    h5: {
      fontSize: '1.25rem'
    },
    h6: {
      fontSize: '1.125rem'
    },
    subtitle1: {
      fontWeight: 900,
    },
    subtitle2: {
      color: '#9e9e9e',
      fontWeight: 400,
    },
    body1: {
      fontSize: 16,
      lineHeight: '1.5em', // Adjusted line height for better readability
      color: '#000',
    },
    body2: {
      fontSize: 12,
      lineHeight: '1em',
      color: '#000',
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingTop: 'env(safe-area-inset-top, 20px)',
          [theme.breakpoints.down('sm')]: {
            height: styles.mobileAppBarHeight,
          },
          [theme.breakpoints.up('sm')]: {
            height: styles.appBarHeight,
          },
          padding: theme.spacing(4),
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down('md')]: {
            padding: styles.mediumCardPadding
          },
          [theme.breakpoints.up('md')]: {
            padding: styles.mediumCardPadding
          },
          [theme.breakpoints.up('lg')]: {
            padding: styles.largeCardPadding
          }
        })
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: '1px solid #e0e0e0', // Subtle border added
          borderRadius: '5px',
          "&:hover": {
            border: '0px',
          },
          "&::before": {
            border: '0px',
          },
          "&::after": {
            border: '0px',
          },
          "&:hover:not(.Mui-disabled):before": {
            border: '0px',
          }
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: `${theme.palette.error.light} !important`,
          marginRight: '1rem',
          marginLeft: '1rem'
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline'
            },
            cursor: 'pointer'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          span: {
            margin: 0,
          }
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: "#14b57b",
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1000,
      lg: 1200,
      xl: 1920,
    },
  }
});

//Types for the variant property on the mui components
type textFieldType = "filled" | "standard" | "outlined" | undefined
const textFieldVariant: textFieldType = 'outlined'

export const styles = {
  appBarHeight: 90,
  mobileAppBarHeight: 60,
  bottomBarHeight: 60,
  textFieldVariant,
  largePagePadding: 50,
  mediumPagePadding: 20,
  smallPagePadding: 10,
  mobilePadding: 2,
  largeCardPadding: 20,
  mediumCardPadding: 10,
  smallCardPadding: 4,
  maxWidth: 2520,
  bottomBookingHeight: 90
};
