import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Stack,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox, SelectChangeEvent,
} from '@mui/material';
import { Field, Formik, Form, useFormikContext } from 'formik';
import employeeReviewModel, { employeeReviewType } from '../forms/models/employeeReviewModel';
import {
  EmployeeReviewData,
  usePostAddEmployeeReviewMutation,
} from '../../redux/vmsApi';
import LoadingCover from '../common/LoadingCover';
import employeeReviewSchema from '../forms/schemas/employeeReviewSchema';
import PageWithHeader from "../common/PageWithHeader";
import {push} from "connected-react-router/immutable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useAppDispatch} from "../../redux/hooks";
import {generatePastMonths} from "../../utils/periodUtil";

type MyFormValues = employeeReviewType;

const employeeReviewModelInitial = employeeReviewModel.initialValues;

type Props = {
  onSuccess?: (invite: EmployeeReviewData) => void;
};

const {
  formField: {
    reviewPeriod,
    accomplishments,
    quarterlyGoals,
    monthlyGoals,
    quarterlyObjective,
    areaToImprove,
    examplesOfExcellence,
    mainGoalToAchieve,
    turnUpOnTime,
    dailyCheckIn,
    reportsOnTime,
    accomplishmentTitle,
    accomplishmentRating,
    quarterlyTitle,
    quarterlyRating,
    monthlyTitle,
    monthlyRating,
  },
} = employeeReviewModel;

const ratingOptions = [
  { value: undefined, label: 'None', color: 'Transparent' },
  { value: 'A+', label: 'A+', color: 'Platinum' },
  { value: 'A', label: 'A', color: 'Diamond' },
  { value: 'A-', label: 'A-', color: 'Gold' },
  { value: 'B+', label: 'B+', color: 'Silver' },
  { value: 'B', label: 'B', color: 'Emerald' },
  { value: 'C', label: 'C', color: 'Jade' },
  { value: 'C-', label: 'C-', color: 'Copper' },
  { value: 'D', label: 'D', color: 'Bronze' },
  { value: 'E', label: 'E', color: 'Brass' },
  { value: 'F', label: 'F', color: 'Wood' },
];

const SPEARFields: React.FC = () => {
  const { errors, touched, values, setFieldValue } = useFormikContext<any>();

  const textFieldVariant = 'outlined';

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFieldValue(name, checked);
  };

  const monthOptions = generatePastMonths();

  const [quarterNumber, setQuarterNumber] = useState('1');

  const getQuarterNumberFromDate = (reviewPeriodValue: string) => {
    const parts = reviewPeriodValue.match(/(\w+)\s(\d{4})/);
    if (!parts) return '1'; // Default to Q1 if parsing fails

    const [, monthName, year] = parts;
    const month = new Date(`${monthName} 1, ${year}`).getMonth();
    return Math.ceil((month + 1) / 3).toString();
  };


  useEffect(() => {
    const quarter = getQuarterNumberFromDate(values[reviewPeriod.name]);
    setQuarterNumber(quarter);
  }, [values[reviewPeriod.name]]);

  return (
    <Stack width="100%" spacing={4}>
      <Field
        {...reviewPeriod}
        as={FormControl}
        fullWidth
        error={touched[reviewPeriod.name] && Boolean(errors[reviewPeriod.name])}
      >
        <InputLabel>Review Period</InputLabel>
        <Select
          {...reviewPeriod}
          value={values[reviewPeriod.name]}
          displayEmpty
          inputProps={{ 'aria-label': 'Review Period' }}
          autoComplete="off"
          onChange={(event: SelectChangeEvent) => {
            setFieldValue(reviewPeriod.name, event.target.value);
          }}
        >
          {monthOptions.map((quarter, index) => (
            <MenuItem key={index} value={quarter}>{quarter}</MenuItem>
          ))}
        </Select>
        {touched[reviewPeriod.name] && errors[reviewPeriod.name] && (
          <FormHelperText>{errors[reviewPeriod.name]}</FormHelperText>
        )}
      </Field>
      <Typography>TOP 5 accomplishments duties & responsibilities (as per job description)</Typography>
      <Grid container spacing={2} justifyContent="center" alignContent="center">
        {[...Array(5)].map((_, index) => (
          <Grid item xs={12} container key={index} spacing={2}>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" component="div">
                {index + 1}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TextField
                variant={textFieldVariant}
                fullWidth
                type="textarea"
                multiline
                autoComplete="off"
                {...accomplishmentTitle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let updatedAccomplishments = values.accomplishments ? values.accomplishments.slice() : [];
                  if (!updatedAccomplishments[index]) {
                    updatedAccomplishments[index] = { title: '', rating: '' };
                  }
                  updatedAccomplishments[index].title = e.target.value;
                  setFieldValue('accomplishments', updatedAccomplishments);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id={`rating-label-${index}`}>{accomplishmentRating.label}</InputLabel>
                <Field
                  {...accomplishmentRating}
                  as={Select}
                  {...accomplishmentRating}
                  name={`${accomplishmentRating.name}${index}`}
                  value={values.accomplishments[index]?.rating || ''}
                  onChange={(event: SelectChangeEvent) => {
                    const updatedAccomplishments = values.accomplishments.slice();
                    if (!updatedAccomplishments[index]) {
                      updatedAccomplishments[index] = { title: '', rating: '' };
                    }
                    updatedAccomplishments[index].rating = event.target.value;
                    setFieldValue('accomplishments', updatedAccomplishments);
                  }}
                >
                  {ratingOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Typography>OKR's for Q{quarterNumber} 2024</Typography>
      <Grid item xs={7}>
        <Field
          as={TextField}
          variant={textFieldVariant}
          fullWidth
          {...quarterlyObjective}
          type="text"
          multiline
          maxRows={4}
          autoComplete="off"
          error={touched[quarterlyObjective.name] && Boolean(errors[quarterlyObjective.name])}
          helperText={(touched[quarterlyObjective.name] && errors[quarterlyObjective.name]) ? errors[quarterlyObjective.name] : ''}
        />
      </Grid>
      <Grid container spacing={2} justifyContent="center" alignContent="center">
        {[...Array(4)].map((_, index) => (
          <Grid item xs={12} container key={index} spacing={2}>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" component="div">
                KR{index + 1}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Field
                as={TextField}
                variant={textFieldVariant}
                fullWidth
                {...quarterlyTitle}
                type="textarea"
                multiline
                autoComplete="off"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let updatedQuarterlyGoals = values.quarterlyGoals.slice();
                  if (!updatedQuarterlyGoals[index]) {
                    updatedQuarterlyGoals[index] = { title: '', rating: '' };
                  }
                  updatedQuarterlyGoals[index].title = e.target.value;
                  setFieldValue('quarterlyGoals', updatedQuarterlyGoals);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id={`rating-label-${index}`}>{quarterlyRating.label}</InputLabel>
                <Field
                  {...quarterlyRating}
                  as={Select}
                  name={`${quarterlyGoals.name}${index}`}
                  value={values.quarterlyGoals[index]?.rating || ''}
                  onChange={(event: SelectChangeEvent) => {
                    const updatedQuarterlyGoals = values.quarterlyGoals.slice();
                    if (!updatedQuarterlyGoals[index]) {
                      updatedQuarterlyGoals[index] = { title: '', rating: '' };
                    }
                    updatedQuarterlyGoals[index].rating = event.target.value;
                    setFieldValue('quarterlyGoals', updatedQuarterlyGoals);
                  }}
                >
                  {ratingOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Typography>Monthly Goals</Typography>
      <Grid container spacing={2} justifyContent="center" alignContent="center">
        {[...Array(4)].map((_, index) => (
          <Grid item xs={12} container key={index} spacing={2}>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" component="div">
                {index + 1}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Field
                as={TextField}
                variant={textFieldVariant}
                fullWidth
                {...monthlyTitle}
                type="textarea"
                multiline
                autoComplete="off"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let updatedMonthlyGoals = values.monthlyGoals.slice();
                  if (!updatedMonthlyGoals[index]) {
                    updatedMonthlyGoals[index] = { title: '', rating: '' };
                  }
                  updatedMonthlyGoals[index].title = e.target.value;
                  setFieldValue('monthlyGoals', updatedMonthlyGoals);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id={`rating-label-${index}`}>{monthlyRating.label}</InputLabel>
                <Field
                  {...monthlyRating}
                  name={`${monthlyRating.name}${index}`}
                  as={Select}
                  onChange={(event: SelectChangeEvent) => {
                    const updatedMonthlyGoals = values.monthlyGoals.slice();
                    if (!updatedMonthlyGoals[index]) {
                      updatedMonthlyGoals[index] = { title: '', rating: '' };
                    }
                    updatedMonthlyGoals[index].rating = event.target.value;
                    setFieldValue('monthlyGoals', updatedMonthlyGoals);
                  }}
                >
                  {ratingOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Field
        as={TextField}
        variant={textFieldVariant}
        fullWidth
        {...areaToImprove}
        type="text"
        multiline
        maxRows={4}
        autoComplete="off"
        error={touched[areaToImprove.name] && Boolean(errors[areaToImprove.name])}
        helperText={(touched[areaToImprove.name] && errors[areaToImprove.name]) ? errors[areaToImprove.name] : ''}
      />
      <Field
        as={TextField}
        variant={textFieldVariant}
        fullWidth
        {...examplesOfExcellence}
        type="textarea"
        multiline
        maxRows={4}
        autoComplete="off"
        error={touched[examplesOfExcellence.name] && Boolean(errors[examplesOfExcellence.name])}
        helperText={(touched[examplesOfExcellence.name] && errors[examplesOfExcellence.name]) ? errors[examplesOfExcellence.name] : ''}
      />
      <Field
        as={TextField}
        variant={textFieldVariant}
        fullWidth
        {...mainGoalToAchieve}
        type="text"
        multiline
        maxRows={4}
        autoComplete="off"
        error={touched[mainGoalToAchieve.name] && Boolean(errors[mainGoalToAchieve.name])}
        helperText={(touched[mainGoalToAchieve.name] && errors[mainGoalToAchieve.name]) ? errors[examplesOfExcellence.name] : ''}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values[turnUpOnTime.name]}
            onChange={handleCheckboxChange}
          />
        }
        {...turnUpOnTime}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values[dailyCheckIn.name]}
            onChange={handleCheckboxChange}
          />
        }
        {...dailyCheckIn}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values[reportsOnTime.name]}
            onChange={handleCheckboxChange}
          />
        }
        {...reportsOnTime}
      />
    </Stack>
  );
};

const SPEAR: React.FC<Props> = ({ onSuccess }) => {
  const [createEmployeeReview, { isSuccess, isLoading }] = usePostAddEmployeeReviewMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useAppDispatch();

  const initialValues: MyFormValues = {
    ...employeeReviewModelInitial,
  };

  const handleSubmit = async (values: MyFormValues, { resetForm }: { resetForm: () => void }) => {
    const data = {
      reviewPeriod: values.reviewPeriod,
      accomplishments: values.accomplishments,
      quarterlyGoals: values.quarterlyGoals,
      monthlyGoals: values.monthlyGoals,
      areaToImprove: values.areaToImprove,
      examplesOfExcellence: values.examplesOfExcellence,
      mainGoalToAchieve: values.mainGoalToAchieve,
      turnUpOnTime: values.turnUpOnTime,
      dailyCheckIn: values.dailyCheckIn,
      reportsOnTime: values.reportsOnTime,
      quarterlyObjective: values.quarterlyObjective,
    } as EmployeeReviewData;
    setShowOverlay(true);
    try {
      const result = await createEmployeeReview({ employeeReviewData: values }).unwrap();
      setIsSubmitted(true);
      resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  if (showSuccessMsg) {
    return <Typography>Thank you, your response has been received</Typography>;
  }

  return (
    <PageWithHeader>
      {!isSubmitted ? (
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={employeeReviewSchema}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Button color="primary" onClick={() => dispatch(push('/reviews'))}>
                    <ArrowBackIcon/>
                    <Typography color="primary" align="left">Dashboard</Typography>
                  </Button>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                  <Button color="primary" onClick={() => dispatch(push('/reviews/spear/historic'))}>
                    <Typography color="primary" align="right">View Historic Submissions</Typography>
                  </Button>
                </Grid>
              </Grid>
              <Typography variant='h1' gutterBottom style={{fontWeight: 'bold', color: '#123456'}}>
                Self Performance Evaluation and Review (SPEAR)
              </Typography>
              <Typography variant="body1" gutterBottom>
                The Self Performance Evaluation and Review is designed to help you reflect on your accomplishments,
                areas of excellence, and goals for the upcoming quarter and month. Please fill in the review period,
                evaluate your top five accomplishments based on your job description, set quarterly and monthly goals,
                and identify areas for improvement. Your performance will be assessed using a rating system, and you'll
                also be asked to set a main goal to achieve. Consistent attendance, daily check-ins, and timely report
                submissions are also part of this evaluation.
              </Typography>
              <SPEARFields />
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Stack>
          </Form>
        </Formik>
      ) : (
        <Stack spacing={4} width="100%" p={10} pb={5}>
          <Typography variant='h5' gutterBottom style={{color: '#123456'}}>
            Submission Successful!
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                disabled
              />
            }
            label="Thank you for your submission."
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => dispatch(push('/reviews'))}
          >
            Back to Dashboard
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsSubmitted(false)}
          >
            Submit Another
          </Button>
        </Stack>
      )}
    </PageWithHeader>
  );
};

export default SPEAR;
