import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EmployeeReviewDashboard from "./EmployeeReviewDashboard";
import LifeWheel from "./LifeWheel";
import SPEAR from "./SPEAR";
import FeedbackSurvey from "./FeedbackSurvey";
import FeedbackSurveyResponses from "./FeedbackSurveyResponses";
import HistoricLifeWheel from "./HistoricLifeWheel";
import HistoricSPEAR from "./HistoricSPEAR";
import EmployeeReviewAdmin from "./EmployeeReviewAdmin";
import AdminSPEAR from "./AdminSPEAR";
import PrivateRoute from "../common/PrivateRoute";

const EmployeeReviewRoutes = () => {
  return (
    <Switch>
      <Route exact path="/reviews" component={EmployeeReviewDashboard} />

      <PrivateRoute exact path="/reviews/admin" component={EmployeeReviewAdmin} roles={['admin']} />
      <PrivateRoute exact path="/reviews/admin/spear/:uuid" component={AdminSPEAR} roles={['admin']} />

      <Route exact path="/reviews/life-wheel" component={LifeWheel} />
      <Route exact path="/reviews/life-wheel/historic" component={HistoricLifeWheel} />

      <Route exact path="/reviews/spear" component={SPEAR} />
      <Route exact path="/reviews/spear/historic" component={HistoricSPEAR} />

      <Route exact path="/reviews/360-degree-survey" component={FeedbackSurvey} />
      <Route exact path="/reviews/360-degree-survey/feedback" component={FeedbackSurveyResponses} />
    </Switch>
  );
}

export default EmployeeReviewRoutes;
