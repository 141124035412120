import React from 'react';
import LoginForm from './LoginForm'
import {useAppSelector} from "../../redux/hooks";
import ResetRequestForm from './ResetRequestForm';
import CreateAccount from "./CreateAccount";

interface OwnProps {
    closeLoginModal: () => void
    showLoginMsg?: boolean;
}
const AuthenticationContainer = (props: OwnProps) => {
    const authState = useAppSelector(state => state.auth.authState);
    if (authState === 'create') {
        return <CreateAccount close={props.closeLoginModal} showLoginMsg={props.showLoginMsg} />
    }
    else if (authState === 'reset') {
        return <ResetRequestForm close={props.closeLoginModal} />
    }
    else {
        return <LoginForm close={props.closeLoginModal} />
    }
}

export default AuthenticationContainer;
