import React from 'react';
import {InviteWithRelations, useGetInvitesQuery} from "../../redux/vmsApi";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

const UpcomingMeetings: React.FC = () => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {data, isLoading} = useGetInvitesQuery({timeZone: userTimeZone});

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Visitor Name</TableCell>
            <TableCell align="right">Meeting With</TableCell>
            <TableCell align="right">Meeting Date/Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((invite: InviteWithRelations) => (
            <TableRow key={invite.id}>
              <TableCell component="th" scope="row">
                {invite.visitorName}
              </TableCell>
              <TableCell align="right">{invite.meetingWith}</TableCell>
              <TableCell align="right">
                {new Date(invite.meetingDateTime ?? '').toLocaleString(
                  'en-GB', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UpcomingMeetings;
