import React from 'react';
import PageWithHeader from './PageWithHeader';
import { Box, Typography, Button, Container, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Unauthorised: React.FC = () => {
  const handleSupport = () => {
    window.location.href = 'mailto:it@westprop.com?subject=Support Request';
  };

  return (
    <PageWithHeader>
      <Container maxWidth="sm" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '90vh', textAlign: 'center' }}>
        <Paper elevation={2} sx={{ padding: 20, borderRadius: '30px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CloseIcon sx={{ fontSize: 100, color: 'red' }} />
            <Typography variant="h2" sx={{ mt: 2 }}>
              401 Unauthorised
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
              You do not have access to this page.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 4 }}
              onClick={handleSupport}
            >
              Contact Support
            </Button>
          </Box>
        </Paper>
      </Container>
    </PageWithHeader>
  );
};

export default Unauthorised;
